const types = {
  SET_IN_PROGRESS_MATERNITY_LEAVES: "SET_IN_PROGRESS_MATERNITY_LEAVES",
  SET_ARCHIVED_MATERNITY_LEAVES: "SET_ARCHIVED_MATERNITY_LEAVES",
  ADD_OR_UPDATE_MATERNITY_LEAVE: "ADD_OR_UPDATE_MATERNITY_LEAVE",
  DELETE_MATERNITY_LEAVE: "DELETE_MATERNITY_LEAVE",
  SET_MATERNITY_LEAVE_REPLACEMENT_STAFF:
    "SET_MATERNITY_LEAVE_REPLACEMENT_STAFF",
  ADD_OR_UPDATE_MATERNITY_LEAVE_REPLACEMENT_STAFF:
    "ADD_OR_UPDATE_MATERNITY_LEAVE_REPLACEMENT_STAFF",
  DELETE_MATERNITY_LEAVE_REPLACEMENT_STAFF:
    "DELETE_MATERNITY_LEAVE_REPLACEMENT_STAFF",
};

export default types;
