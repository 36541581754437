import React, { useState, useEffect, useCallback } from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { alpha, styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import AddNewTeacherDialog from "../components/AddNewTeacherDialog";

import {
  getUnits,
  deleteSchool,
  getShoolTeachers,
  getSchoolLevels,
  getSectors,
  deleteTeacher,
  setAsActingHT,
  getJobs,
  getQualificationLevels,
  getSchoolPositions,
  lockDistrict,
  suspendStaff,
} from "../../../../store/posts/actions";

import { getExitReasons } from "../../../../store/placement/actions";

import { connect, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import {
  IconButton,
  Tooltip,
  Chip,
  Menu,
  MenuItem,
  Divider,
  Badge,
  Skeleton,
  Button,
  Autocomplete,
  TextField,
  Alert,
} from "@mui/material";
import SearchBox from "../../common/components/SearchBox";
import AppBar from "@mui/material/AppBar";
import { Typography, Box, Toolbar } from "@mui/material";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ReactPaginate from "react-paginate";
import arrayToTree from "array-to-tree";
import AddOrUpdatePositionDialog from "../components/AddOrUpdatePositionDialog";
import PositionEmployeesDialog from "../components/PositionBudgetPostsDialog";
import BudgetPostRequestDialog from "../components/BudgetPostRequestDialog";
import UpdateTeacherDialog from "../components/UpdateTeacherDialog";
import { CSVLink } from "react-csv";
import moment from "moment";
import MoveStaffDialog from "../components/MoveStaffDialog";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    // right: -3,
    // top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

const StyledTreeItem = styled((props) => <TreeItem {...props} />)(
  ({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
      "& .close": {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  })
);

const StaffList = (props) => {
  useEffect(() => {
    document.title = "TMIS - Staff management";
  });

  const {
    loading,
    user,
    units,
    getUnits,
    deleteSchool,
    setSearchUnit,
    getShoolTeachers,
    schoolTeachers,
    selectedUnit,
    getSchoolLevels,
    getSectors,
    deleteTeacher,
    setAsActingHT,
    getJobs,
    jobs,
    getQualificationLevels,
    getSchoolPositions,
    getExitReasons,
    exitReasons,
    lockDistrict,
    suspendStaff,
  } = props;
  const [expandedNodes, setExpandedNodes] = useState([]);
  const [selectedNode, setSelectedNode] = useState("");
  const [parentUnit, setParentUnit] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const [showAddNewTeacherDialog, setShowAddNewTeacherDialog] = useState(false);

  const handleToggleNode = (event, nodeIds) => {
    setExpandedNodes(nodeIds);
  };

  const handleSelectNode = (event, nodeId) => {
    setSelectedNode(nodeId);
  };
  const handleAddUnitDialog = (event, node) => {
    setParentUnit(node);

    setSelectedTreeItem(node);

    // setSelectedUnit("");
    setIsEditing(false);
    event.stopPropagation();
  };
  //handleEditUnitDialog
  const handleEditUnitDialog = (event, node) => {
    // setSelectedUnit(node);
    setSchoolFormData(node);
    setIsEditing(true);
    // setParentUnit("");

    event.stopPropagation();
  };

  const getTreeUnits = () => {
    if (user.selectedEntity.role !== "HEAD_TEACHER") getUnits(setExpandedNodes);
    // else {
    //   setExpandedNodes([units.find(({ parentId }) => parentId === null).id]);
    // }

    if (!!user.selectedEntity && user.selectedEntity.type === "DISTRICT") {
      getSectors(user.selectedEntity.id);
    }
  };

  useEffect(
    () => {
      getTreeUnits();
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user.selectedEntity, user.selectedAcademicYear]
  );

  // useEffect(() => {
  //   if (
  //     !!selectedUnit &&
  //     user.selectedEntity.role !== "HEAD_TEACHER" &&
  //     !!selectedUnit.id
  //   )
  //     getSchoolPositions(selectedUnit.type, selectedUnit.id);
  // }, [selectedUnit]);

  useEffect(() => {
    if (user.selectedEntity.role === "HEAD_TEACHER")
      getSchoolPositions("SCHOOL", user.selectedEntity.id);

    getExitReasons();
  }, []);

  const renderTree = (node) => (
    <StyledTreeItem
      style={{ padding: "0" }}
      key={node.id}
      nodeId={node.id}
      label={
        <TreeListItem
          loading={loading}
          user={user}
          // setHoveredNode={setHoveredNode}
          labelClicked={labelClicked}
          setSelectedTreeItem={setSelectedTreeItem}
          deleteSchool={deleteSchool}
          selectedNode={selectedNode}
          handleLockDistrict={handleLockDistrict}
          node={node}
          handleAddUnitDialog={handleAddUnitDialog}
          handleEditUnitDialog={handleEditUnitDialog}
          // setSchollToBeRemoved={setSchollToBeRemoved}
          // setConfirmRemoveSchool={setConfirmRemoveSchool}
          // handleUnitAllowanceDialog={handleUnitAllowanceDialog}
          // handleUnitDeductionDialog={handleUnitDeductionDialog}
        />
      }
    >
      {Array.isArray(node.children)
        ? node.children.map((childNode) => renderTree(childNode))
        : null}
    </StyledTreeItem>
  );

  const [selectedTreeItem, setSelectedTreeItem] = useState(null);
  const [confirmLocking, setConfirmLocking] = useState(false);
  const handleLockDistrict = (event, node) => {
    setSelectedTreeItem(node);
    setConfirmLocking(true);
    event.stopPropagation();
  };

  const dispatch = useDispatch();

  const labelClicked = useCallback((event, node) => {
    setSelectedNode(node.id);

    dispatch({
      type: "SET_SELECTED_UNIT",
      data: node,
    });

    setSelectedTreeItem(node);

    if (!!node && user.selectedEntity.role !== "HEAD_TEACHER" && !!node.id) {
      getShoolTeachers(node.type, node.id);
      getSchoolPositions(node.type, node.id);
    }

    event.stopPropagation();
  }, []);

  // useEffect(() => {
  //   if (
  //     !!selectedUnit &&
  //     !loading &&
  //     user.selectedEntity.role !== "HEAD_TEACHER" &&
  //     !!selectedUnit.id
  //   )
  //     getShoolTeachers(selectedUnit.type, selectedUnit.id);
  // }, [selectedUnit]);

  useEffect(() => {
    dispatch({
      type: "SET_SCHOOL_TEACHERS",
      data: [],
    });

    if (user.selectedEntity.role === "HEAD_TEACHER")
      getShoolTeachers("SCHOOL", user.selectedEntity.id);
  }, []);

  const getNo = (data, key, idToSearch) =>
    data.findIndex((item) => item[key] === idToSearch);

  // useEffect(() => {
  //   console.log(paginatedItems);
  // }, [paginatedItems]);

  const [schoolFormData, setSchoolFormData] = useState({
    id: null,
    name: "",
    schoolLevelId: "",
    schoolCategoryId: 1,
    sectorId: "",
    cellId: "",
    villageId: "",
    sdmsSchoolCode: "",
    ippisUnitId: "",
  });

  const getTreeData = (data) => {
    const treeData = arrayToTree(data, {
      parentProperty: "parentId",
      childrenProperty: "children",
      customID: "id",
    });
    return treeData;
  };

  // const [positionFormData, setTeacherFormData] = useState({
  //   id: null,
  //   schoolId: selectedUnit?.id,
  //   positionId: "",
  //   budgetPosts: 0,
  // });

  const [teacherForm, setTeacherForm] = useState({
    id: null,
    nidNumber: "",
    email: "",
    phoneNumber: "",
    gender: "",
    genderId: "",
    countryOfBirth: "",
    countryIdOfBirth: "",
    firstName: "",
    lastName: "",
    civilStatus: "",
    civilStatusId: "",
    spouseNames: "",
    fatherNames: "",
    motherNames: "",
    dateOfBirth: "",
    placeOfBirth: "",
    nationalityId: "",
    villageId: "",
    placementDate: null,
    profilePicture: "",
  });

  const [unitSearchTerm, setUnitSearchTerm] = useState("");
  const [filteredUnitItems, setFilteredUnitItems] = useState([...units]);

  //====UNIT SEARCH====//
  useEffect(() => {
    const filtered = [...units].filter(
      ({ districtName, sectorName, schoolName }) =>
        ((districtName || "") + (sectorName || "") + (schoolName || ""))
          .replace(/\s/g, "")
          .toLowerCase()
          .includes(unitSearchTerm.toLowerCase().replace(/\s/g, ""))
    );
    setFilteredUnitItems(filtered);
  }, [unitSearchTerm, units]);

  const onUnitSearch = (term) => {
    setUnitSearchTerm(term);
  };

  //====STAFF SEARCH====//
  const [staffSearchTerm, setStaffSearchTerm] = useState("");
  const [filteredStaffItems, setFilteredStaffItems] = useState([
    ...schoolTeachers,
  ]);

  useEffect(() => {
    const filtered = [...schoolTeachers].filter(
      ({
        lastName,
        firstName,
        nidNumber,
        phoneNumber,
        email,
        schoolName,
        sectorName,
        positionName,
      }) =>
        (
          (lastName || "") +
          (firstName || "") +
          (nidNumber || "") +
          (phoneNumber || "") +
          (email || "") +
          (schoolName || "") +
          (sectorName || "") +
          (positionName || "")
        )
          .replace(/\s/g, "")
          .toLowerCase()
          .includes(staffSearchTerm.toLowerCase().replace(/\s/g, ""))
    );
    setFilteredStaffItems(filtered);

    paginate(filtered);
  }, [staffSearchTerm, schoolTeachers]);

  const onStaffSearch = (term) => {
    setStaffSearchTerm(term);
  };

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 15;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(filteredStaffItems);
  }, [itemOffset, itemsPerPage, filteredStaffItems]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredStaffItems.length || 0;

    setItemOffset(newOffset);
  };

  return (
    <div className="">
      <div className="row mx-0 no-gutters">
        {user.selectedEntity.role !== "HEAD_TEACHER" && (
          <div className={`col-4`}>
            <div className="border rounded">
              <AppBar position="static" elevation={0} className="app-bar w-100">
                <Toolbar
                  style={{
                    minHeight: "43px",

                    borderBottom: "3px solid #e9ecef",
                  }}
                  className=" my-0 py-1"
                >
                  <Typography
                    variant="p"
                    noWrap
                    component="div"
                    className="d-flex ilign-items-center text-uppercase text-primary font-weight-bold"
                  >
                    Staff Management
                  </Typography>

                  <Box sx={{ flexGrow: 1 }} />
                  {user.selectedEntity.id !== "00000000" && (
                    <Box
                      sx={{
                        display: { xs: "flex", md: "flex", width: "150px" },
                      }}
                    >
                      <SearchBox
                        onSearch={onUnitSearch}
                        placeholder="Search…"
                      />
                    </Box>
                  )}
                </Toolbar>
              </AppBar>

              {!!filteredUnitItems.length && (
                <div
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "0.5rem",
                    boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                    MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                    height: "69vh",
                    overflowY: "auto",
                  }}
                >
                  {getTreeData(filteredUnitItems).map((treeItem) => (
                    <TreeView
                      key={treeItem.id}
                      style={{
                        padding: "0.5em 1em",
                      }}
                      defaultCollapseIcon={<MinusSquare />}
                      defaultExpandIcon={<PlusSquare />}
                      defaultEndIcon={<CloseSquare />}
                      expanded={expandedNodes}
                      selected={selectedNode}
                      onNodeToggle={handleToggleNode}
                      onNodeSelect={handleSelectNode}
                    >
                      {renderTree(treeItem)}
                    </TreeView>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        <div
          className={`${
            user.selectedEntity.role === "HEAD_TEACHER" ? "col-12" : "col-8"
          } `}
        >
          <div className="border rounded">
            <AppBar position="static" elevation={0} className="app-bar w-100">
              <Toolbar
                style={{
                  minHeight: "0px",
                  borderBottom: "3px solid #e9ecef",
                }}
                className=" my-0 py-1"
              >
                <Typography
                  variant="p"
                  noWrap
                  component="div"
                  className="d-flex  align-items-center text-uppercase text-primary font-weight-bold"
                >
                  <span className="text-dark ">
                    ({filteredStaffItems.length}) Teachers{" "}
                  </span>
                  <span className="material-icons" style={{ color: "#cecece" }}>
                    chevron_right
                  </span>
                  {/* <DragIndicatorIcon  /> */}
                  <span
                    className="d-block text-truncate"
                    style={{ maxWidth: "70%" }}
                  >
                    {user.selectedEntity.role !== "HEAD_TEACHER" && (
                      <span>
                        {selectedUnit?.name}{" "}
                        {!!selectedUnit?.type &&
                        !(selectedUnit?.name)
                          .toUpperCase()
                          .includes(selectedUnit?.type)
                          ? selectedUnit?.type
                          : ""}
                      </span>
                    )}
                    {user.selectedEntity.role === "HEAD_TEACHER" && (
                      <span>
                        {user.selectedEntity?.name}{" "}
                        {!!user.selectedEntity?.type &&
                        !(user.selectedEntity?.name)
                          .toUpperCase()
                          .includes(user.selectedEntity?.type)
                          ? user.selectedEntity?.type
                          : ""}
                      </span>
                    )}
                  </span>
                  <CSVLink
                    data={schoolTeachers}
                    filename={`Staff list - ${
                      selectedUnit?.name || user.selectedEntity?.name
                    }.csv`}
                    className="hidden"
                    id="downloadBTN"
                    target="_blank"
                  />

                  <Tooltip title="Download">
                    <span className="ml-2">
                      <IconButton
                        size="small"
                        disabled={loading || !schoolTeachers.length}
                        className=" mr-1"
                        color="primary"
                        onClick={() =>
                          document.getElementById("downloadBTN").click()
                        }
                      >
                        <span
                          className="material-icons"
                          // style={{ fontSize: "28px" }}
                        >
                          file_download
                        </span>
                      </IconButton>
                    </span>
                  </Tooltip>
                </Typography>

                <Box sx={{ flexGrow: 1 }} />

                {(selectedUnit?.type === "SCHOOL" ||
                  user.selectedEntity.type === "SCHOOL") && (
                  <Button
                    onClick={() => {
                      setShowAddNewTeacherDialog(true);
                    }}
                    variant="contained"
                    size="small"
                    disableElevation
                    className="mr-2"
                    color="primary"
                    style={{
                      borderRadius: "8px",
                      height: "32px",
                      // border: `1px solid rgb(7, 142, 206)`,
                      // color: "#fff",
                      alignItems: "center",
                      fontSize: ".75rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textTransform: "initial",
                      // backgroundColor: "rgb(7, 142, 206)",
                    }}
                  >
                    <span className="material-icons">add</span>
                    New Staff
                  </Button>
                )}
                <Box
                  sx={{ display: { xs: "flex", md: "flex" }, width: "150px" }}
                >
                  <SearchBox onSearch={onStaffSearch} placeholder="Search…" />
                </Box>
              </Toolbar>
            </AppBar>

            <div
              style={{
                backgroundColor: "#f3f4f6",
                borderRadius: "0.5rem",
                boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                height: "60vh",
                overflowY: "auto",
              }}
              className="px-3"
            >
              <table className="table table-striped table-hover  fixTableHead">
                {!!filteredStaffItems.length && (
                  <thead>
                    <tr style={{ backgroundColor: "#f0f2f5" }}>
                      <th scope="col">#</th>
                      <th scope="col" style={{ width: "128px" }}>
                        EmployeeId
                      </th>

                      {/* <th scope="col">Staff Code</th> */}

                      {selectedUnit.type === "DISTRICT" && (
                        <th scope="col">Sector</th>
                      )}

                      {selectedUnit.type !== "SCHOOL" && (
                        <th scope="col">School</th>
                      )}

                      {/* {selectedUnit.type !== "SCHOOL" && (
                        <th scope="col">Teaching Level</th>
                      )} */}

                      <th scope="col">Names</th>
                      <th scope="col">NID / Passport</th>
                      {/* <th scope="col">Phone Number</th> */}
                      {/* <th scope="col">Email</th> */}
                      <th scope="col">Position</th>

                      <th scope="col">Status</th>

                      <th scope="col" className="text-right">
                        Actions
                      </th>
                    </tr>
                  </thead>
                )}

                {!!paginatedItems && (
                  <tbody>
                    {paginatedItems.map((teacher) => (
                      <TeacherCard
                        user={user}
                        key={teacher.teacherPlacementId}
                        index={getNo(
                          filteredStaffItems,
                          "teacherPlacementId",
                          teacher.teacherPlacementId
                        )}
                        teacher={teacher}
                        exitReasons={exitReasons}
                        selectedUnit={selectedUnit}
                        deleteTeacher={deleteTeacher}
                        setAsActingHT={setAsActingHT}
                        teacherForm={teacherForm}
                        setTeacherForm={setTeacherForm}
                        getTreeUnits={getTreeUnits}
                        suspendStaff={suspendStaff}
                      />
                    ))}
                  </tbody>
                )}
              </table>
              {!paginatedItems?.length && (
                <div className="jumbotron jumbotron-fluid text-center">
                  <div className="container py-5 ">
                    <p className="lead">
                      {loading ? "Fetching data..." : "No record found"}{" "}
                    </p>
                  </div>
                </div>
              )}

              {/* {loading && <Skeleton variant="rectangular" height={260} />} */}
            </div>
            {!!filteredStaffItems.length && (
              <div className="mt-3">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
                  previousLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
                  nextLinkClassName="btn btn-outline-dark btn-sm ml-1"
                  activeLinkClassName="active"
                />
              </div>
            )}
          </div>

          {showAddNewTeacherDialog && (
            <AddNewTeacherDialog
              showDialog={showAddNewTeacherDialog}
              setShowDialog={setShowAddNewTeacherDialog}
            />
          )}

          {confirmLocking && (
            <ConfirmationDialog
              btnMessage={
                <>
                  {!!selectedTreeItem[`is${user.selectedScope.id}Locked`] && (
                    <>un</>
                  )}
                  lock
                </>
              }
              confirmationDialog={confirmLocking}
              message={
                <>
                  <strong>Are you sure,</strong> <br />
                  You want to{" "}
                  {!!selectedTreeItem[`is${user.selectedScope.id}Locked`] && (
                    <>un</>
                  )}
                  lock {selectedTreeItem.name}?
                </>
              }
              setConfirmationDialog={setConfirmLocking}
              onYes={() => {
                lockDistrict(
                  selectedTreeItem,
                  setConfirmLocking,
                  user.selectedScope.id
                );
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  user,
  loading,
  units,
  schoolTeachers,
  selectedUnit,
  jobs,
  exitReasons,
}) => {
  return {
    user,
    units,
    loading,
    schoolTeachers,
    selectedUnit,
    jobs,
    exitReasons,
  };
};
export default connect(mapStateToProps, {
  getUnits,
  deleteSchool,
  getShoolTeachers,
  getSchoolLevels,
  getQualificationLevels,
  getSectors,
  deleteTeacher,
  setAsActingHT,
  getJobs,
  getSchoolPositions,
  getExitReasons,
  lockDistrict,
  suspendStaff,
})(StaffList);

const TreeListItem = (props) => {
  const [hoveredNode, setHoveredNode] = useState("");

  const {
    loading,
    user,
    labelClicked,
    setSelectedTreeItem,
    selectedNode,
    node,
    handleEditUnitDialog,
    // setSchollToBeRemoved,
    // setConfirmRemoveSchool,
    // handleUnitAllowanceDialog,
    // handleUnitDeductionDialog,
    handleAddUnitDialog,
    deleteSchool,
    handleLockDistrict,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);

    event.stopPropagation();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [confirmRemoveSchool, setConfirmRemoveSchool] = useState(false);

  return (
    <>
      <span
        // onMouseOut={() => setHoveredNode("")}
        // onMouseOver={() => setHoveredNode(node.id)}
        onClick={(e) => labelClicked(e, node)}
        className="d-flex justify-content-between  align-items-center"
        style={{ maxWidth: "100%" }}
      >
        <span
          className={`d-block text-truncate add-padding`}
          style={{ maxWidth: "60%" }}
        >
          <Tooltip title={node.name}>
            <span
              className="mr-2"
              style={{
                cursor: "default",
              }}
            >
              {node.name}
            </span>
          </Tooltip>
        </span>

        <span>
          {node.type === "SCHOOL" && (
            <>
              {node.numberOfStaff > 0 && (
                <StyledBadge
                  showZero
                  badgeContent={node.numberOfStaff || 0}
                  color="secondary"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  {!!node.isWing && (
                    <Tooltip title="TVET WING">
                      <small
                        style={{
                          position: "absolute",
                          left: "-40px",
                        }}
                      >
                        <span className="badge badge-success">WING</span>
                      </small>
                    </Tooltip>
                  )}
                  {node.schoolCategoryId === 2 && (
                    <Tooltip title="Private">
                      <i className="fas fa-shield-alt mr-1 text-primary"></i>
                    </Tooltip>
                  )}
                  <span
                    className="badge badge-secondary disabled text-truncate"
                    style={{
                      maxWidth: "64px",
                    }}
                  >
                    <small> {node.schoolLevelName || "N/A"}</small>
                  </span>
                </StyledBadge>
              )}
              {node.numberOfStaff === 0 && (
                <StyledBadge
                  showZero
                  badgeContent={node.numberOfStaff || 0}
                  color="error"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <span
                    className="badge badge-secondary disabled text-truncate"
                    style={{
                      maxWidth: "64px",
                    }}
                  >
                    <small> {node.schoolLevelName || "N/A"}</small>
                  </span>
                </StyledBadge>
              )}

              {/* <Badge
              badgeContent={4}
              color="primary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <span className="badge badge-secondary disabled">
                <small> {node.schoolLevelName || "N/A"}</small>
              </span>
            </Badge> */}
            </>
          )}
          {node.type === "DISTRICT" && (
            <span className="badge badge-primary">
              {(node.children || []).reduce(function (total, childNode) {
                return total + (childNode?.children?.length || 0);
              }, 0)}{" "}
              schools
            </span>
          )}
          {node.type === "SECTOR" && (
            <span className="badge badge-info">
              {node?.children?.length || 0} schools
            </span>
          )}
          <IconButton
            onClick={(e) => {
              if (node.type !== "DISTRICT") handleOpenMenu(e);
              else {
                if (["REB", "RTB"].includes(user.selectedEntity.role))
                  handleLockDistrict(e, node);
                else e.stopPropagation();
              }
            }}
            size="small"
            className="ml-2"
            aria-label="settings"
          >
            <Badge color="info">
              {node.type === "DISTRICT" &&
                !node[`is${user.selectedScope.id}Locked`] && (
                  <span className="material-icons">lock_open</span>
                )}

              {node.type === "DISTRICT" &&
                !!node[`is${user.selectedScope.id}Locked`] && (
                  <span className="material-icons text-danger">lock</span>
                )}
            </Badge>
          </IconButton>
          {/*  <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={(e) => {
              handleCloseMenu();
              e.stopPropagation();
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {node.type === "SECTOR" && (
              <span>
                <MenuItem
                  onClick={(e) => {
                    handleCloseMenu();
                    e.stopPropagation();

                    handleAddUnitDialog(e, node);
                  }}
                  className="text-primary font-weight-bolder"
                >
                  <span className="material-icons  mr-1">add</span>
                  Add new school
                </MenuItem>
              </span>
            )}
            {node.type === "SCHOOL" && (
              <span>
                <MenuItem
                  onClick={(e) => {
                    handleCloseMenu();
                    handleEditUnitDialog(e, node);
                    e.stopPropagation();
                  }}
                  className="text-primary font-weight-bolder"
                >
                  <span className="material-icons  mr-1">edit_note</span>
                  Update
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}

            {node.type === "SCHOOL" && (
              <span>
                <MenuItem
                  onClick={(e) => {
                    handleCloseMenu();
                    // setSchollToBeRemoved(node);
                    setConfirmRemoveSchool(true);
                    e.stopPropagation();
                  }}
                  className="text-danger"
                >
                  <span className="material-icons mr-1">
                    remove_circle_outline
                  </span>
                  Remove
                </MenuItem>
              </span>
            )}
          </Menu> */}
        </span>
      </span>
      {/* {confirmRemoveSchool && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveSchool}
          message={`Are you sure you want to remove this school?`}
          setConfirmationDialog={setConfirmRemoveSchool}
          onYes={() => {
            deleteSchool(
              node,
              setConfirmRemoveSchool
              // setSchollToBeRemoved,
              // setExpandedNodes,
              // units
            );
          }}
        />
      )} */}
    </>
  );
};

const TeacherCard = (props) => {
  const {
    user,
    teacher,
    index,
    selectedUnit,
    deleteTeacher,
    setAsActingHT,
    exitReasons,
    getTreeUnits,
    suspendStaff,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [confirmSuspendStaff, setConfirmSuspendStaff] = useState(false);
  const [confirmRemoveTeacher, setConfirmRemoveTeacher] = useState(false);

  const [showMoveStaffDialog, setShowMoveStaffDialog] = useState(false);

  const [confirmSetActing, setConfirmSetActing] = useState(false);

  const [showTeacherProfile, setShowTeacherProfile] = useState(false);

  const [exitReason, setExitReason] = useState(null);
  const [exitComments, setExitComments] = useState("");

  const filterExitreasons = () => {
    if (["REB", "RTB"].includes(user.selectedEntity.role))
      return [...exitReasons, { id: 12, name: "Position update" }];
    else return exitReasons;
  };

  return (
    <>
      <tr
        className={`${
          teacher.status !== "Active" || !!teacher.isSuspended ? "red" : ""
        } ${!!teacher.isHeadTeacher ? "bolder" : ""}`}
      >
        <td>{index + 1}</td>

        <th scope="row" className="text-left">
          {teacher.id}
        </th>

        {/* <td>{teacher.sdmsStaffCode}</td> */}
        {selectedUnit?.type === "DISTRICT" && <td>{teacher.sectorName}</td>}
        {selectedUnit?.type !== "SCHOOL" && (
          <td>
            {teacher.schoolName}
            <span className="badge badge-secondary ml-1">
              {teacher.teachingLevelName}
            </span>
          </td>
        )}
        {/* {selectedUnit?.type !== "SCHOOL" && (
          <td></td>
        )} */}
        <td>
          {teacher.lastName} {teacher.firstName}
        </td>

        <td className="text-left">
          <span className="d-flex align-items-center justify-content-center">
            {teacher.nidNumber || teacher.passportNumber}

            {!teacher.nidNumber && !!teacher.passportNumber && (
              <Tooltip title="Passport Number">
                <span
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  className="material-icons"
                >
                  card_membership
                </span>
              </Tooltip>
            )}

            {!!teacher.nidNumber && !!teacher.isNIDVerified && (
              <Tooltip title="Verified">
                <span
                  className="material-icons ml-1 text-success"
                  style={{ fontSize: "14px", cursor: "pointer" }}
                >
                  verified
                </span>
              </Tooltip>
            )}

            {!!teacher.nidNumber && !teacher.isNIDVerified && (
              <Tooltip title="Not Verfied">
                <span
                  className="material-icons ml-1 text-danger"
                  style={{ fontSize: "14px", cursor: "pointer" }}
                >
                  report_problem
                </span>
              </Tooltip>
            )}
          </span>
        </td>
        {/* <td className="text-left">{teacher.phoneNumber}</td> */}
        {/* <td className="text-left">{teacher.email}</td> */}
        <td className="text-left">
          {teacher.positionName}{" "}
          {teacher.isActingHT && (
            <span className="badge badge-primary ml-1">(Acting HT)</span>
          )}
        </td>
        <td className="text-center">
          <span
            className={`badge badge-${
              teacher.status === "Active" ? "success" : "danger"
            }`}
          >
            {teacher.status}
          </span>
          {teacher.status !== "Active" && (
            <>
              <br />
              <span className="">
                {moment(teacher.exitDate).format("lll")}
                {/* by: {teacher.exitedBy} */}
              </span>
            </>
          )}{" "}
          {!!teacher.isTransfered && (
            <>
              <br />
              <span className="badge badge-pill text-primary">Transfer</span>
            </>
          )}
        </td>

        <td className="text-right">
          <IconButton
            size="small"
            className=" p-0"
            onClick={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                disabled={
                  teacher.status !== "Active" && teacher.status !== "Suspended"
                }
                onClick={() => {
                  handleCloseMenu();

                  setShowTeacherProfile(true);
                }}
                className="text-primary font-weight-bolder"
              >
                <span className="material-icons mr-1">account_circle</span>
                Update profile
              </MenuItem>
              <Divider className="my-1" />
            </span>

            <span>
              <MenuItem
                disabled={teacher.status !== "Active"}
                onClick={() => {
                  setConfirmSetActing(true);

                  handleCloseMenu();
                }}
                style={{ color: !teacher.isActingHT ? "#000" : "#df8706" }}
              >
                {!teacher.isActingHT && (
                  <span className="material-icons mr-1">bookmark_add</span>
                )}
                {teacher.isActingHT && (
                  <span className="material-icons mr-1">bookmark_remove</span>
                )}
                {teacher.isActingHT ? "Remove " : "Set as "} acting HT
              </MenuItem>
              <Divider className="my-1" />
            </span>

            <span>
              <MenuItem
                disabled={teacher.status !== "Active"}
                onClick={() => {
                  setShowMoveStaffDialog(true);
                  handleCloseMenu();
                }}
                className="text-dark"
              >
                <span
                  style={{
                    transform: "rotateY(180deg)",
                    fontSize: "18px",
                  }}
                  className="material-icons mr-1"
                >
                  reply
                </span>
                Move staff
              </MenuItem>
              <Divider className="my-1" />
            </span>

            <span>
              <MenuItem
                disabled={
                  teacher.status !== "Active" && teacher.status !== "Suspended"
                }
                onClick={(e) => {
                  handleCloseMenu();
                  setConfirmSuspendStaff(true);
                }}
                className={`text-${
                  !teacher.isSuspended ? "warning" : "primary"
                }`}
              >
                {!teacher.isSuspended && (
                  <span className="material-icons mr-1">not_interested</span>
                )}
                {!!teacher.isSuspended && (
                  <span className="material-icons mr-1">
                    check_circle_outline
                  </span>
                )}
                {!teacher.isSuspended ? "Suspend staff" : "Resume staff"}
              </MenuItem>
            </span>

            <span>
              <MenuItem
                disabled={
                  teacher.status !== "Active" && teacher.status !== "Suspended"
                }
                onClick={(e) => {
                  handleCloseMenu();

                  setConfirmRemoveTeacher(true);
                }}
                className="text-danger"
              >
                <span className="material-icons mr-1">delete_forever</span>
                Remove
              </MenuItem>
            </span>
          </Menu>
        </td>
      </tr>

      {confirmSuspendStaff && (
        <ConfirmationDialog
          disabled={!exitComments && !teacher.isSuspended}
          confirmationDialog={confirmSuspendStaff}
          message={
            <>
              Are you sure you want to{" "}
              <strong
                className={`text-${
                  !teacher.isSuspended ? "warning" : "primary"
                }`}
              >
                {!teacher.isSuspended ? "suspend" : "resume"}{" "}
              </strong>{" "}
              {teacher.lastName} {teacher.firstName}?
              {!teacher.isSuspended && (
                <div className="mt-3">
                  <TextField
                    multiline
                    rows={2}
                    fullWidth
                    size="small"
                    name="reasonForSuspension"
                    variant="outlined"
                    label="Reason for suspension"
                    placeholder="Reason for suspension"
                    value={exitComments}
                    onChange={(e) => {
                      setExitComments(e.target.value || "");
                    }}
                  />
                </div>
              )}
            </>
          }
          setConfirmationDialog={setConfirmSuspendStaff}
          onYes={() => {
            suspendStaff(
              {
                teacherPlacementId: teacher.teacherPlacementId,
                teacherId: teacher.id,
                isSuspended: !teacher.isSuspended,
                reasonForSuspension: exitComments,
              },
              (e) => (setConfirmSuspendStaff(false), setExitReason(null))
            );
          }}
        />
      )}

      {confirmRemoveTeacher && (
        <ConfirmationDialog
          disabled={!exitReason || !exitComments}
          confirmationDialog={confirmRemoveTeacher}
          message={
            <>
              Are you sure you want to{" "}
              <strong className="text-danger">remove</strong>{" "}
              <strong className="text-primary">
                {teacher.lastName} {teacher.firstName}
              </strong>
              ?
              <Alert severity="warning" icon={false}>
                Be careful in removing a teacher, if she/he will again be
                registered on another school, he/she should be given offer on an
                upgraded level
              </Alert>
              <div className="mt-3">
                <Autocomplete
                  fullWidth
                  size="small"
                  defaultValue={null}
                  value={exitReason || null}
                  options={exitReasons}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, exitReason) => {
                    setExitReason(exitReason || null);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, exitReason) => (
                    <Box component="li" {...props}>
                      {exitReason.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select exit reason"
                      name="exitReason"
                      placeholder="Select exit reason"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </div>
              <div className="mt-3">
                <TextField
                  multiline
                  rows={2}
                  fullWidth
                  size="small"
                  name="exitComments"
                  label="Comments"
                  variant="outlined"
                  placeholder="Comments"
                  value={exitComments}
                  onChange={(e) => {
                    setExitComments(e.target.value || "");
                  }}
                />
              </div>
            </>
          }
          setConfirmationDialog={setConfirmRemoveTeacher}
          onYes={() => {
            deleteTeacher(
              {
                ...teacher,
                exitReasonId: exitReason?.id || null,
                exitComments,
              },
              () => (setConfirmRemoveTeacher(false), setExitReason(null))
            );
          }}
        />
      )}

      {showTeacherProfile && (
        <UpdateTeacherDialog
          showDialog={showTeacherProfile}
          setShowDialog={setShowTeacherProfile}
          teacher={teacher}
        />
      )}

      {showMoveStaffDialog && (
        <MoveStaffDialog
          showDialog={showMoveStaffDialog}
          setShowDialog={setShowMoveStaffDialog}
          teacher={teacher}
          getTreeUnits={getTreeUnits}
        />
      )}

      {confirmSetActing && (
        <ConfirmationDialog
          confirmationDialog={confirmSetActing}
          message={
            <>
              {!teacher.isActingHT && (
                <>
                  Are you sure? <br /> You want to set{" "}
                  <strong className="text-primary">
                    {teacher.lastName} {teacher.firstName}
                  </strong>{" "}
                  as acting head teacher?
                </>
              )}
              {teacher.isActingHT && (
                <>
                  Are you sure? <br /> You want to remove acting head teacher on{" "}
                  <strong className="text-primary">
                    {teacher.lastName} {teacher.firstName}
                  </strong>
                  ?
                </>
              )}
            </>
          }
          setConfirmationDialog={setConfirmSetActing}
          onYes={() => {
            setAsActingHT(
              {
                teacherPlacementId: teacher.teacherPlacementId,
                teacherId: teacher.id,
                isActingHT: !teacher.isActingHT,
              },
              setConfirmSetActing
            );
          }}
        />
      )}
    </>
  );
};
