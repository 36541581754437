import React from "react";
import { Skeleton } from "@mui/material";

export const SkeletonPieChart = ({ title }) => {
  return (
    <div className="border rounded p-2 dashboard-card text-center">
      <span style={{ fontSize: "12px", fontWeight: "700" }}>{title}</span>
      <Skeleton
        variant="circular"
        width={100}
        height={100}
        className="mx-auto mt-2 mb-3"
      />
    </div>
  );
};
