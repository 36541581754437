import { defaultState } from "./state";
import cpd from "./action-types";
import commonTypes from "../common/action-types";

const types = { ...commonTypes, ...cpd };

const inProgressMaternityLeaves = (
  inProgressMaternityLeavesState = defaultState.inProgressMaternityLeaves,
  action
) => {
  switch (action.type) {
    case types.SET_IN_PROGRESS_MATERNITY_LEAVES:
      return action.data;

    case types.ADD_OR_UPDATE_MATERNITY_LEAVE: {
      const tmpState = [...inProgressMaternityLeavesState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0)
        tmpState[index] = { no: tmpState[index].no, ...action.data };
      else if (!!action.data)
        tmpState.push({ no: tmpState.length + 1, ...action.data });
      return tmpState;
    }

    case types.DELETE_MATERNITY_LEAVE: {
      const tmpState = [...inProgressMaternityLeavesState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.inProgressMaternityLeaves;

    default:
      return inProgressMaternityLeavesState;
  }
};

const archivedMaternityLeaves = (
  archivedMaternityLeavesState = defaultState.archivedMaternityLeaves,
  action
) => {
  switch (action.type) {
    case types.SET_ARCHIVED_MATERNITY_LEAVES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.archivedMaternityLeaves;

    default:
      return archivedMaternityLeavesState;
  }
};

const maternityLeaveReplacements = (
  maternityLeaveReplacementsState = defaultState.maternityLeaveReplacements,
  action
) => {
  switch (action.type) {
    case types.SET_MATERNITY_LEAVE_REPLACEMENT_STAFF:
      return action.data;

    case types.ADD_OR_UPDATE_MATERNITY_LEAVE_REPLACEMENT_STAFF: {
      const tmpState = [...maternityLeaveReplacementsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0)
        tmpState[index] = { no: tmpState[index].no, ...action.data };
      else if (!!action.data)
        tmpState.push({ no: tmpState.length + 1, ...action.data });
      return tmpState;
    }

    case types.DELETE_MATERNITY_LEAVE_REPLACEMENT_STAFF: {
      const tmpState = [...maternityLeaveReplacementsState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.maternityLeaveReplacements;

    default:
      return maternityLeaveReplacementsState;
  }
};

export default {
  inProgressMaternityLeaves,
  archivedMaternityLeaves,
  maternityLeaveReplacements,
};
