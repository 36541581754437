import axios from "axios";
import { showSuccess, showError } from "../../app/toastify";

import commonTypes from "../common/action-types";
import postsTypes from "./action-types";
import placementTypes from "../placement/action-types";

const types = { ...commonTypes, ...postsTypes, ...placementTypes };

export const getUnits = (setExpandedNodes) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/posts/units");

      dispatch({
        type: types.SET_UNITS,
        data,
      });

      setExpandedNodes([data.find(({ parentId }) => parentId === null).id]);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveOrUpdateSchool = (
  schoolFormData,
  setSchoolFormData,
  setIsEditing,
  setShowDialog,

  isEditing
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/posts/schools", schoolFormData);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      if (!!schoolFormData?.id)
        dispatch({
          type: types.UPDATE_UNIT,
          data,
        });
      else
        dispatch({
          type: types.ADD_NEW_UNIT,
          data,
        });

      showSuccess(!isEditing ? "Saved successfully" : "Updated successfully");

      setSchoolFormData({
        id: null,
        name: "",
        schoolLevelId: "",
        schoolCategoryId: 0,
        sectorId: "",
        cellId: "",
        villageId: "",
        sdmsSchoolCode: "",
        ippisUnitId: "",
      });

      setShowDialog(false);
      setIsEditing(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const deleteSchool = (
  schoolToBeRemoved,
  setConfirmRemoveSchool
  // setSchollToBeRemoved,
  // setExpandedNodes,
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/posts/schools/" + schoolToBeRemoved.id);

      dispatch({
        type: types.DELETE_UNIT,
        id: schoolToBeRemoved.id,
      });

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      setConfirmRemoveSchool(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const setAsTVETWing = (school, setShowDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/posts/set-as-tvet-wing/" + school.id
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.UPDATE_UNIT,
        data,
      });

      showSuccess("Saved successfully");

      setShowDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const lockDistrict = (district, setConfirmLocking, scopeId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/posts/lock-district/" + district.id
      );

      const d = { ...district };

      d[`is${scopeId}Locked`] = data[`is${scopeId}Locked`];

      dispatch({
        type: types.UPDATE_UNIT,
        data: d,
      });

      dispatch({ type: types.END_LOADING });

      setConfirmLocking(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getSchools = (sectorId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_SCHOOLS,
        data: [],
      });

      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/posts/schools/" + sectorId);

      dispatch({
        type: types.SET_SCHOOLS,
        data,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getPositions = (type, id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_POSITIONS,
        data: [],
      });

      const { data } = await axios.get(
        "/api/posts/school-positions/" + type + "/" + id
      );

      dispatch({
        type: types.SET_POSITIONS,
        data,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getSchoolPositions = (type, id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_SCHOOL_POSITIONS,
        data: [],
      });

      const { data } = await axios.get(
        "/api/posts/school-positions/" + type + "/" + id
      );

      dispatch({
        type: types.SET_SCHOOL_POSITIONS,
        data,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const saveOrUpdateSchoolPosition = (
  formData,
  setFormData,
  setIsEditing,
  setShowDialog
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/posts/school-positions",
        formData
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.UPDATE_UNIT,
        data: data.school,
      });

      if (!!formData?.id)
        dispatch({
          type: types.UPDATE_SCHOOL_POSITION,
          data: data.position,
        });
      else
        dispatch({
          type: types.ADD_NEW_SCHOOL_POSITION,
          data: data.position,
        });

      showSuccess(
        !formData?.id ? "Saved successfully" : "Updated successfully"
      );

      setFormData({
        id: null,
        schoolId: "",
        positionId: "",
        budgetPosts: 0,
      });

      setShowDialog(false);
      setIsEditing(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const deleteSchoolPosition = (positionToBeRemoved, setConfirm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/posts/school-positions/" + positionToBeRemoved.id
      );

      setConfirm(false);

      dispatch({
        type: types.DELETE_SCHOOL_POSITION,
        id: positionToBeRemoved.id,
      });

      dispatch({
        type: types.UPDATE_UNIT,
        data,
      });

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getOrganigramData = (entitySectorId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/structure/organigram-data/" + entitySectorId
      );

      dispatch({
        type: types.SET_ORGANIGRAM_DATA,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getJobs = () => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/posts/jobs");

      dispatch({
        type: types.SET_JOBS,
        data,
      });

      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getSectors = (districtId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });

      dispatch({
        type: types.SET_SECTORS,
        data: [],
      });

      dispatch({
        type: types.SET_CELLS,
        data: [],
      });

      dispatch({
        type: types.SET_VILLAGES,
        data: [],
      });

      const { data } = await axios.get("/api/posts/sectors/" + districtId);

      dispatch({
        type: types.SET_SECTORS,
        data,
      });

      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getCells = (sectorId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });

      dispatch({
        type: types.SET_CELLS,
        data: [],
      });

      dispatch({
        type: types.SET_VILLAGES,
        data: [],
      });

      const { data } = await axios.get("/api/posts/cells/" + sectorId);

      dispatch({
        type: types.SET_CELLS,
        data,
      });

      // if (!!cb) cb();

      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getVillages = (cellId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });

      dispatch({
        type: types.SET_VILLAGES,
        data: [],
      });

      const { data } = await axios.get("/api/posts/villages/" + cellId);

      dispatch({
        type: types.SET_VILLAGES,
        data,
      });

      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getSchoolLevels = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/posts/school-levels");

      dispatch({
        type: types.SET_SCHOOL_LEVELS,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const getQualificationLevels = () => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/posts/qualification-levels");

      dispatch({
        type: types.SET_QUALIFICATION_LEVELS,
        data,
      });

      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getPositionBudgetPosts = (positionId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      // dispatch({ type: types.START_WAIT });

      dispatch({
        type: types.SET_POSITION_BUDGET_POSTS,
        data: [],
      });

      const { data } = await axios.get(
        "/api/posts/school-position-budget-posts/" + positionId
      );

      dispatch({
        type: types.SET_POSITION_BUDGET_POSTS,
        data: data.budgetPosts,
      });

      dispatch({
        type: types.UPDATE_SCHOOL_POSITION,
        data: data.position,
      });

      // dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const deleteBudgetPost = (budgetPostId, setConfirmRemoveBudgetPost) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({ type: types.START_WAIT });

      const { data } = await axios.delete(
        "/api/posts/budget-posts/" + budgetPostId
      );

      dispatch({
        type: types.DELETE_POSITION_BUDGET_POST,
        id: budgetPostId,
      });

      dispatch({
        type: types.UPDATE_SCHOOL_POSITION,
        data: data,
      });

      setConfirmRemoveBudgetPost(false);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const saveBudgetPostRequest = (formData, setFormData, setShowDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/posts/budget-post-requests",
        formData
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.ADD_OR_UPDATE_BUDGET_POST_REQUEST,
        data,
      });

      showSuccess("Submitted successfully");

      setFormData({
        number: 0,
        reason: "",
      });

      setShowDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const deleteBudgetPostRequest = (id, setShowDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/posts/budget-post-requests/" + id);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      setShowDialog(false);

      dispatch({
        type: types.DELETE_BUDGET_POST_REQUEST,
        id,
      });

      showSuccess("Removed successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getBudgetPostRequests = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_BUDGET_POST_REQUESTS,
        data: [],
      });

      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/posts/budget-post-requests");

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_BUDGET_POST_REQUESTS,
        data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const approveBudgetPostRequest = (formData, setShowDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/posts/budget-post-requests",
        formData
      );

      setShowDialog(false);

      dispatch({
        type: types.ADD_OR_UPDATE_BUDGET_POST_REQUEST,
        data,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getREBPostDistributions = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/posts/reb-post-distributions");

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_REB_POST_DISTRIBUTIONS,
        data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getREBPostLimits = () => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/posts/reb-post-limits");

      dispatch({
        type: types.SET_REB_POST_LIMITS,
        data,
      });

      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveREBPostLimits = (formData, setShowDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/posts/reb-post-limits", formData);

      setShowDialog(false);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_REB_POST_LIMITS,
        data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveDistrictPostLimits = (formData, setSaved) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/posts/district-post-limits",
        formData
      );

      setSaved(true);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_REB_POST_LIMITS,
        data: data.limit,
      });

      dispatch({
        type: types.UPDATE_REB_POST_DISTRIBUTION,
        data: data.distribution,
      });

      showSuccess("Saved");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const confirmREBPostDistributions = (
  { status },
  setShowConfirmDistributions
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/posts/confirm-reb-post-distributions",
        { status }
      );

      setShowConfirmDistributions(false);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_SELECTED_ACADEMIC_YEAR,
        data,
      });

      dispatch({
        type: types.UPDATE_ACADEMIC_YEAR,
        data,
      });

      showSuccess(
        `Post distribution ${status ? "confirmed" : "canceled"} successfully`
      );
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

//===============================================//

export const getDDEPostDistributions = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/posts/dde-post-distributions");

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_DDE_POST_DISTRIBUTIONS,
        data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getDDEPostLimits = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/posts/dde-post-limits");

      dispatch({
        type: types.SET_DDE_POST_LIMITS,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveSchoolPostLimits = (formData, setSaved) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/posts/school-post-limits",
        formData
      );

      setSaved(true);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_DDE_POST_LIMITS,
        data: data.limit,
      });

      dispatch({
        type: types.UPDATE_DDE_POST_DISTRIBUTION,
        data: data.distribution,
      });

      showSuccess("Saved");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const confirmDDEPostDistributions = (setShowConfirmDistributions) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/posts/confirm-dde-post-distributions"
      );

      setShowConfirmDistributions(false);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_SELECTED_USER_ENTITY,
        data,
      });

      showSuccess("Post distribution confirmed successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

//===============================================//

export const getHTPostDistributions = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/posts/post-distributions");

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_HT_POST_DISTRIBUTIONS,
        data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getHTPostLimits = () => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/posts/ht-post-limits");

      dispatch({
        type: types.SET_HT_POST_LIMITS,
        data,
      });

      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const savePositionAdditionalBudgetPosts = (
  formData,
  setConfirmAdditionalBudgetPosts
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/posts/confirm-approved-position-budget-posts",
        formData
      );

      setConfirmAdditionalBudgetPosts(false);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_HT_POST_LIMITS,
        data: data.limit,
      });

      dispatch({
        type: types.ADD_OR_UPDATE_BUDGET_POST_REQUEST,
        data: data.request,
      });

      showSuccess("Confirmed successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getDistricts = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/posts/districts");

      dispatch({
        type: types.SET_DISTRICTS,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const getShoolTeachers = (type, id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_SCHOOL_TEACHERS,
        data: [],
      });

      const { data } = await axios.get(
        "/api/posts/school-teachers/" + type + "/" + id
      );

      dispatch({
        type: types.SET_SCHOOL_TEACHERS,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

// export const saveOrUpdateSchoolTeacher = (
//   formData,
//   setFormData,
//   setIsEditing,
//   setShowDialog
// ) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: types.END_WAIT });

//       dispatch({ type: types.START_LOADING });

//       const { data } = await axios.post("/api/posts/school-teachers", formData);

//       dispatch({ type: types.END_LOADING });
//       dispatch({ type: types.END_WAIT });

//       dispatch({
//         type: types.UPDATE_UNIT,
//         data: data.school,
//       });

//       if (!!formData?.id)
//         dispatch({
//           type: types.UPDATE_SCHOOL_TEACHER,
//           data: data.teacher,
//         });
//       else
//         dispatch({
//           type: types.ADD_NEW_SCHOOL_TEACHER,
//           data: data.teacher,
//         });

//       showSuccess(
//         !formData?.id ? "Saved successfully" : "Updated successfully"
//       );

//       setFormData({
//         id: null,
//         schoolId: formData.schoolId,
//         positionId: "",
//         budgetPosts: 0,
//       });

//       setShowDialog(false);
//       setIsEditing(false);
//     } catch (error) {
//       dispatch({ type: types.END_LOADING });
//       dispatch({ type: types.END_WAIT });
//       showError(error);
//     }
//   };
// };

export const addOrUpdateTeacher = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/posts/teachers", formData);
      onClose();

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.UPDATE_SCHOOL_POSITION,
        data: data.position,
      });

      dispatch({
        type: types.UPDATE_UNIT,
        data: data.school,
      });

      dispatch({
        type: types.ADD_OR_UPDATE_NEW_SCHOOL_TEACHER,
        data: data.teacher,
      });

      if (!!data.placementReport) {
        dispatch({
          type: types.UPDATE_PLACEMENT_REPORT,
          data: data.placementReport,
        });

        dispatch({
          type: types.SET_PLACEMENT_CHANGE_LIST,
          data: data.changeList,
        });

        dispatch({
          type: types.SET_PLACEMENT_REPORT_DETAILS,
          data: data.placementReportDetails,
        });

        dispatch({
          type: types.SET_PREV_PLACEMENT_REPORT,
          data: data.prevPlacementReport,
        });
      }

      showSuccess("Saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const moveTeacher = (teacher, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/posts/move-teacher", {
        teacherPlacementId: teacher.teacherPlacementId,
        fromSchoolId: teacher.schoolId,
        fromSchoolPositionId: teacher.schoolPositionId,
        toSchoolPositionId: teacher.toSchoolPositionId,
        exitComments: teacher.exitComments,
      });

      dispatch({
        type: types.DELETE_SCHOOL_TEACHER,
        id: teacher.id,
      });

      dispatch({
        type: types.UPDATE_UNIT,
        data: data.school,
      });

      dispatch({
        type: types.UPDATE_SCHOOL_POSITION,
        data: data.position,
      });

      dispatch({ type: types.END_LOADING });
      showSuccess("Teacher moved successfully");

      cb();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const deleteTeacher = (teacherToBeRemoved, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/posts/remove-staff", {
        teacherPlacementId: teacherToBeRemoved.teacherPlacementId,
        schoolPositionId: teacherToBeRemoved.schoolPositionId,
        schoolId: teacherToBeRemoved.schoolId,
        exitReasonId: teacherToBeRemoved.exitReasonId,
        exitComments: teacherToBeRemoved.exitComments,
      });

      dispatch({
        type: types.DELETE_SCHOOL_TEACHER,
        id: teacherToBeRemoved.id,
      });

      dispatch({
        type: types.UPDATE_UNIT,
        data: data.school,
      });

      dispatch({
        type: types.UPDATE_SCHOOL_POSITION,
        data: data.position,
      });

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      cb();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const setAsActingHT = (formData, setConfirmSetActing) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/posts/set-as-acting-ht",
        formData
      );

      setConfirmSetActing(false);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.ADD_OR_UPDATE_NEW_SCHOOL_TEACHER,
        data: data,
      });

      showSuccess("Saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getDropdownPositions = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_SYSTEM_SETTING_POSITIONS,
        data: [],
      });
      const { data } = await axios.get("/api/posts/dropdown-positions");

      dispatch({
        type: types.SET_SYSTEM_SETTING_POSITIONS,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveDropdownPosition = (formData, setFormData, setShowDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/posts/dropdown-positions",
        formData
      );

      dispatch({
        type: types.ADD_OR_UPDATE_SYSTEM_SETTING_POSITION,
        data,
      });

      setShowDialog(false);

      setFormData({
        id: null,
        name: "",
        qualificationLevelId: "",
        classificationId: 1,
        teachingLevelId: "",
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const disableDropdownPosition = (id, handleCloseMenu) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/posts/disable-dropdown-position/" + id
      );

      dispatch({
        type: types.ADD_OR_UPDATE_SYSTEM_SETTING_POSITION,
        data,
      });

      handleCloseMenu();

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const removeDropdownPosition = (id, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/posts/delete-dropdown-position/" + id);

      dispatch({
        type: types.DELETE_SYSTEM_SETTING_POSITION,
        id,
      });

      onClose();

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getPositionRoles = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/posts/position-roles");

      dispatch({
        type: types.SET_POSITION_ROLES,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const moveBadgetPost = (badgetPost, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.post("/api/posts/move-badget-posts", badgetPost);

      const { data: data1 } = await axios.get("/api/posts/reb-post-limits");

      const { data: data2 } = await axios.get(
        "/api/posts/reb-post-distributions"
      );

      dispatch({
        type: types.SET_REB_POST_LIMITS,
        data: data1,
      });

      dispatch({
        type: types.SET_REB_POST_DISTRIBUTIONS,
        data: data2,
      });

      dispatch({ type: types.END_LOADING });

      showSuccess("Moved successfully");

      cb();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const suspendStaff = (formData, setConfirmSetActing) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/posts/suspend-staff", formData);

      setConfirmSetActing(false);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.ADD_OR_UPDATE_NEW_SCHOOL_TEACHER,
        data: data,
      });

      showSuccess("Teacher suspended successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getBudgetPostRequestPositions = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      // dispatch({
      //   type: types.SET_PUDGETPOST_REQUEST_POSITIONS,
      //   data: [],
      // });

      const { data } = await axios.get(
        "/api/posts/budget-post-request-positions"
      );

      dispatch({
        type: types.SET_PUDGETPOST_REQUEST_POSITIONS,
        data,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getTeachingLevels = () => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/posts/teaching-levels");

      dispatch({
        type: types.SET_TEACHING_LEVELS,
        data,
      });

      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
