import {
  IconButton,
  Dialog,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  Box,
  CircularProgress,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
  TextField,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Chip,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import axios from "axios";
import moment from "moment";
import defaultPofile from "../../../assets/default-profile.jpg";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import MaskedInput from "react-text-mask";
import joi from "joi";

import {
  addOrUpdateReplacementStaff,
  getReplacementStaffPositions,
  approveReplacementStaff,
  confirmReplacementStaff,
} from "../../../../store/maternity-leave/actions";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const personalEmailValidator = joi.object({
  email: joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: ["com", "fr", "rw"] },
  }),
});

const idNumberPattern = [
  /[1-3]/,
  " ",
  /[1-2]/,
  /[0|9]/,
  /\d/,
  /\d/,
  " ",
  /[7-8]/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
];

const PreviewReplacementStaffDialog = (props) => {
  const {
    user,
    loading,
    onWait,
    teacher,
    showDialog,
    setShowDialog,
    degrees,
    specializations,
    schoolPosition,

    replacement,
    banks,
    getReplacementStaffPositions,
    approveReplacementStaff,
    confirmReplacementStaff,
    request,
    closePalentDialog,
  } = props;

  const onClose = () => {
    setShowDialog(false);
  };

  const [showUploadedDocument, setShowUploadedDocument] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState(null);

  const [positions, setPositions] = useState([]);

  useEffect(() => {
    getReplacementStaffPositions(replacement.id, (data) => setPositions(data));
  }, []);

  const [showConfirmApproval, setShowConfirmApproval] = useState(false);
  const [statusId, setStatusId] = useState(null);
  const [statusComments, setStatusComments] = useState("");

  const [showConfirmReplacement, setShowConfirmReplacement] = useState(false);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary pb-0 mb-0 text-uppercase border">
          <span className="d-flex align-items-center mb-2">
            <Typography className="d-flex align-items-center text-primary">
              <span className="text-dark mr-1">Replacing Staff Profile</span>
            </Typography>
          </span>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 4,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="px-3 py-0">
          <div className="mt-0">
            <div
              style={{
                display: "table-cell",
                width: "100vw",
                height: "45vh",
                // padding: "10px",
                verticalAlign: "middle",
              }}
            >
              <div className="row mx-0">
                <div className="col-sm-12">
                  <div className="row no-gutters">
                    <div className="col-2 justify-content-center  text-center">
                      <div
                        style={{
                          minHeight: "150px",
                          overflow: "hidden",
                        }}
                      >
                        <>
                          <img
                            className="mt-0 rounded  text-center"
                            src={
                              /* !!teacherForm.profilePicture
                                    ? "data:image/jpg;base64," +
                                      teacherForm.profilePicture
                                    :*/ defaultPofile
                            }
                            style={{
                              border: "1px solid #a7a7a7",
                              height: "150px",
                              width: "120px",
                              overflow: "hidden",
                              borderRadius: "50%",
                            }}
                          />
                        </>
                      </div>

                      <div className="text-primary">
                        <strong>NID PHOTO</strong>{" "}
                      </div>
                      <label className="text-center text-uppercase  mt-n2">
                        <span style={{ fontSize: "12px" }}>
                          {replacement?.lastName} {replacement?.firstName}
                        </span>
                      </label>

                      <FormControl
                        className=" d-block border rounded pt-2"
                        style={{ backgroundColor: "#eee" }}
                      >
                        <FormLabel className="text-primary  px-2">
                          Education certficate
                        </FormLabel>
                        <RadioGroup
                          className="mt-0 d-flex justify-content-start px-4"
                          row
                          name="hasEducationCertficate"
                          value={replacement?.hasEducationCertficate}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio disableRipple size="small" />}
                            label="Certfied"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio disableRipple size="small" />}
                            label="Not certfied"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>

                    <div className="col-10 row mx-0 d-flex">
                      <div className="col-4 justify-content-center">
                        {!replacement.isNIDVerified && (
                          <span className="material-icons loaderIcon text-danger">
                            help
                          </span>
                        )}

                        {!!replacement.isNIDVerified && (
                          <span className="loaderIcon text-success d-flex align-items-center">
                            <span className="material-icons text-success ">
                              verified
                            </span>
                          </span>
                        )}

                        <MaskedInput
                          style={{ backgroundColor: "#e9ecef" }}
                          onKeyDown={(e) => e.preventDefault()}
                          mask={idNumberPattern}
                          className="form-control "
                          placeholder="NID Number"
                          guide={false}
                          value={replacement.nidNumber}
                        />
                      </div>

                      <div className="col-4 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="lastName"
                          InputProps={{
                            readOnly: true,
                          }}
                          label="Last Name"
                          variant="outlined"
                          className=""
                          value={replacement.lastName}
                        />
                      </div>
                      <div className="col-4  text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="firstName"
                          InputProps={{
                            readOnly: true,
                          }}
                          label="First Name"
                          variant="outlined"
                          className=""
                          value={replacement.firstName}
                        />
                      </div>

                      <div className="col-2 mt-2 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="civilStatus"
                          InputProps={{
                            readOnly: true,
                          }}
                          label="Civil Status"
                          variant="outlined"
                          value={replacement.civilStatus}
                        />
                      </div>
                      <div className="col-2 mt-2 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="gender"
                          InputProps={{
                            readOnly: true,
                          }}
                          label="Gender"
                          variant="outlined"
                          className=" font-weight-bold"
                          value={replacement.gender}
                        />
                      </div>

                      <div className="col-4  mt-2 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="dateOfBirth"
                          value={
                            !!replacement.dateOfBirth
                              ? moment(replacement.dateOfBirth).format(
                                  "DD/MM/yyyy"
                                )
                              : ""
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                          label="Date Of Birth"
                          variant="outlined"
                        />
                      </div>
                      <div className="col-4  mt-2 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="countryOfBirth"
                          value={replacement.countryOfBirth}
                          InputProps={{
                            readOnly: true,
                          }}
                          label="Country of Birth"
                          variant="outlined"
                          className=""
                        />
                      </div>
                      <div className="col-4  mt-2 text-left">
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="placeOfBirth"
                          value={replacement.placeOfBirth}
                          label="Place of Birth"
                          variant="outlined"
                          className=""
                        />
                      </div>

                      <div className="col-4  mt-2 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="nationality"
                          InputProps={{
                            readOnly: true,
                          }}
                          label="Nationality"
                          placeholder="Nationality"
                          variant="outlined"
                          className=" font-weight-bold"
                          value={replacement.nationalityId}
                        />
                      </div>

                      <div className="col-4 mt-2 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          fullWidth
                          size="small"
                          name="employeeId"
                          value={replacement?.userId || ""}
                          InputProps={{
                            readOnly: true,
                          }}
                          label="IPPIS Staff ID"
                          placeholder="IPPIS Staff ID"
                          variant="outlined"
                        />
                      </div>

                      <div className="col-4 mt-2 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          InputProps={{ readOnly: true }}
                          fullWidth
                          size="small"
                          name="rssbNumber"
                          label="RSSB Number"
                          placeholder="RSSB Number"
                          variant="outlined"
                          value={replacement.rssbNumber}
                        />
                      </div>

                      <div className="col-4 mt-2 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          InputProps={{ readOnly: true }}
                          fullWidth
                          size="small"
                          name="bankAccountNumber"
                          label="Bank Account Number"
                          placeholder="Bank Account Number"
                          variant="outlined"
                          value={replacement.bankAccountNumber}
                        />
                      </div>
                      <div className="col-4 mt-2 text-left">
                        <Autocomplete
                          style={{ backgroundColor: "#e9ecef" }}
                          readOnly
                          size="small"
                          defaultValue={null}
                          value={
                            banks.find(({ id }) => id === replacement.bankId) ||
                            null
                          }
                          options={banks}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="bank"
                              label="Bank"
                              placeholder="Bank"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </div>

                      <div className="col-4  mt-2 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          InputProps={{ readOnly: true }}
                          fullWidth
                          size="small"
                          autoComplete="false"
                          name="phone"
                          label="Phone number"
                          placeholder="Phone number"
                          variant="outlined"
                          value={replacement.phoneNumber}
                        />
                      </div>

                      <div className="col-4  mt-2 text-left">
                        <TextField
                          style={{ backgroundColor: "#e9ecef" }}
                          InputProps={{ readOnly: true }}
                          fullWidth
                          size="small"
                          autoComplete="false"
                          name="email"
                          label="Personal email"
                          placeholder="Personal email"
                          variant="outlined"
                          value={replacement.email}
                        />
                      </div>

                      <div className="mt-2 col-4">
                        <Autocomplete
                          style={{ backgroundColor: "#e9ecef" }}
                          readOnly
                          size="small"
                          defaultValue={null}
                          value={
                            degrees.find(
                              ({ id }) => id === replacement.degreeId
                            ) || null
                          }
                          options={degrees}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Highest Degree"
                              name="degree"
                              placeholder="Highest Degree"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="mt-2 col-8">
                        <Autocomplete
                          style={{ backgroundColor: "#e9ecef" }}
                          readOnly
                          size="small"
                          defaultValue={null}
                          value={
                            specializations.find(
                              ({ id }) => id === replacement.specializationId
                            ) || null
                          }
                          options={specializations}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Specialization"
                              name="specialization"
                              placeholder="Specialization"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="mt-2 col-4">
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          readOnly
                        >
                          <DesktopDatePicker
                            label="Graduation date"
                            inputFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            value={replacement.graduationDate || null}
                            renderInput={(params) => (
                              <TextField
                                style={{ backgroundColor: "#e9ecef" }}
                                onKeyDown={(e) => e.preventDefault()}
                                size="small"
                                fullWidth
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>

                    <div className="col-12 row mx-0 mt-3">
                      <fieldset
                        className={`col-12 row no-gutters px-2 form-group border border-primary`}
                      >
                        <legend
                          className={`col-12 w-auto px-2 text-${
                            !replacement.degreeUrl ? "danger" : "primary"
                          }`}
                          style={{
                            fontFamily:
                              '"Roboto","Helvetica","Arial",sans-serif',
                            fontWeight: 400,
                            fontSize: "0.82rem",
                            lineHeight: "1em",
                            letterSpacing: "0.00938em",
                          }}
                        >
                          Attachments
                        </legend>

                        <div className="col-6">
                          <fieldset className={`form-group border px-2`}>
                            <legend
                              className="w-auto px-2"
                              style={{
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                                color: `${
                                  !replacement.degreeUrl
                                    ? "red"
                                    : "rgba(0, 0, 0, 0.6)"
                                }`,
                              }}
                            >
                              <span className="ml-1">Degree</span>
                            </legend>
                            <div className="form-group1 mb-2">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fas fa-paperclip"></i>
                                  </span>
                                </div>
                                {!!replacement.degreeUrl ? (
                                  <div
                                    className="form-control"
                                    style={{
                                      backgroundColor: "rgb(229, 246, 253)",
                                      color: "#007bff",
                                    }}
                                  >
                                    <span
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setShowUploadedDocument(true);
                                        setUploadedDocument({
                                          name: "Degree",
                                          url: replacement.degreeUrl,
                                        });
                                      }}
                                    >
                                      Degree
                                    </span>

                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        setShowUploadedDocument(true);
                                        setUploadedDocument({
                                          name: "Degree",
                                          url: replacement.degreeUrl,
                                        });
                                      }}
                                      style={{
                                        marginTop: "-5px",
                                        marginRight: "-10px",
                                      }}
                                      color="info"
                                      className="float-right"
                                      aria-label="view"
                                    >
                                      <span className="material-icons  mr-1">
                                        open_in_new
                                      </span>
                                    </IconButton>
                                  </div>
                                ) : (
                                  <span className="text-danger ml-2">
                                    No file attached
                                  </span>
                                )}
                              </div>
                            </div>
                          </fieldset>
                        </div>
                        <div className="col-6">
                          <fieldset className={`form-group border px-2`}>
                            <legend
                              className="w-auto px-2"
                              style={{
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                                color: `${
                                  !replacement.transcriptUrl
                                    ? "red"
                                    : "rgba(0, 0, 0, 0.6)"
                                }`,
                              }}
                            >
                              <span className="ml-1">
                                Transcript / results slip
                              </span>
                            </legend>
                            <div className="form-group1 mb-2">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fas fa-paperclip"></i>
                                  </span>
                                </div>
                                {!!replacement.transcriptUrl ? (
                                  <div
                                    className="form-control"
                                    style={{
                                      backgroundColor: "rgb(229, 246, 253)",
                                      color: "#007bff",
                                    }}
                                  >
                                    <span
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setShowUploadedDocument(true);
                                        setUploadedDocument({
                                          name: "Transcript",
                                          url: replacement.transcriptUrl,
                                        });
                                      }}
                                    >
                                      Degree
                                    </span>

                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        setShowUploadedDocument(true);
                                        setUploadedDocument({
                                          name: "Transcript",
                                          url: replacement.transcriptUrl,
                                        });
                                      }}
                                      style={{
                                        marginTop: "-5px",
                                        marginRight: "-10px",
                                      }}
                                      color="info"
                                      className="float-right"
                                      aria-label="view"
                                    >
                                      <span className="material-icons  mr-1">
                                        open_in_new
                                      </span>
                                    </IconButton>
                                  </div>
                                ) : (
                                  <span className="text-danger ml-2">
                                    No file attached
                                  </span>
                                )}
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </fieldset>
                    </div>

                    {!!positions.length && (
                      <div className="col-12 row mx-0">
                        <fieldset
                          className={`col-12 row no-gutters px-2 form-group border border-primary bg-light`}
                        >
                          <legend
                            className={`col-12 w-auto px-2 text-primary`}
                            style={{
                              fontFamily:
                                '"Roboto","Helvetica","Arial",sans-serif',
                              fontWeight: 400,
                              fontSize: "0.82rem",
                              lineHeight: "1em",
                              letterSpacing: "0.00938em",
                            }}
                          >
                            Positions
                          </legend>

                          <div className="col-12 pb-2">
                            {positions.map((position) => (
                              <Chip
                                key={position.id}
                                label={position.positionName}
                                variant="outlined"
                                className="mr-2 mb-2"
                              />
                            ))}
                          </div>
                        </fieldset>
                      </div>
                    )}

                    <div className="col-12 mt-n2 text-right">
                      <span
                        className={`badge badge-${
                          replacement.statusId === 2
                            ? "success"
                            : replacement.statusId === 3
                            ? "danger"
                            : "secondary"
                        }`}
                      >
                        {replacement.statusName}{" "}
                        {replacement.statusId !== 1 && (
                          <>By {replacement.statusBy}</>
                        )}
                      </span>

                      {replacement.statusId !== 1 && (
                        <small className="d-block">
                          <span className="badge">
                            {moment(replacement.statusOn).format("lll")}
                          </span>
                        </small>
                      )}

                      {!!replacement.statusComments &&
                        replacement.statusId !== 1 && (
                          <TextField
                            className="bg-light mt-2"
                            InputProps={{ readOnly: true }}
                            multiline
                            rows={3}
                            fullWidth
                            size="small"
                            name="ddeComments"
                            label="Comments"
                            variant="outlined"
                            value={replacement.statusComments}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className=" d-flex justify-content-center">
          {["DDE"].includes(user?.selectedEntity?.role) &&
            replacement.statusId === 1 && (
              <>
                <Button
                  className="ml-2"
                  onClick={() => {
                    setStatusId(2);
                    setShowConfirmApproval(true);
                  }}
                  variant="contained"
                  disableElevation
                  color="success"
                >
                  <span className="material-icons">check</span>
                  Approve
                </Button>

                <Button
                  className="ml-2"
                  onClick={() => {
                    setStatusId(3);

                    setShowConfirmApproval(true);
                  }}
                  variant="contained"
                  disableElevation
                  color="error"
                >
                  <span className="material-icons">close</span>
                  Reject
                </Button>
              </>
            )}

          {["DDE"].includes(user?.selectedEntity?.role) &&
            replacement.statusId === 2 &&
            request?.statusId === 2 &&
            !request?.replacingStaffId &&
            !replacement.selected && (
              <>
                <Button
                  className="ml-2"
                  onClick={() => {
                    setShowConfirmReplacement(true);
                  }}
                  variant="contained"
                  disableElevation
                  color="success"
                >
                  <span className="material-icons">check</span>
                  Confirm
                </Button>
              </>
            )}
        </DialogActions>
      </Dialog>

      {showUploadedDocument && uploadedDocument && (
        <PreviewPdfUrl
          isBase64={true}
          showDocument={showUploadedDocument}
          setShowDocument={setShowUploadedDocument}
          document={{
            name: `${uploadedDocument?.name || ""}`,
            url: "/" + (uploadedDocument?.url || ""),
          }}
        />
      )}

      {showConfirmApproval && (
        <ConfirmationDialog
          disabled={!(!!statusComments || +statusId === 2)}
          confirmationDialog={showConfirmApproval}
          message={
            <>
              <p className="mt-2 mb-0">
                Are you sure you want to{" "}
                <strong
                  className={`text-${statusId === 2 ? "success" : "danger"}`}
                >
                  {" "}
                  {statusId === 2 ? "approve" : "reject"}
                </strong>{" "}
                this staff?
              </p>

              {+statusId === 3 && (
                <TextField
                  className="mt-3"
                  multiline
                  rows={4}
                  fullWidth
                  size="small"
                  name="statusComments"
                  label="Your comments"
                  variant="outlined"
                  placeholder="Your comments"
                  value={statusComments}
                  onChange={(e) => {
                    setStatusComments(e.target.value || "");
                  }}
                />
              )}
            </>
          }
          setConfirmationDialog={setShowConfirmApproval}
          onYes={() => {
            approveReplacementStaff(
              { id: replacement.id, statusId, statusComments },
              () => {
                onClose();
                closePalentDialog();
                setShowConfirmApproval(false);
              }
            );
          }}
        />
      )}

      {showConfirmReplacement && (
        <ConfirmationDialog
          disabled={!statusComments}
          confirmationDialog={showConfirmReplacement}
          message={
            <>
              <p className="mt-2 mb-0">
                Are you sure you want to{" "}
                <strong className={`text-success`}>confirm</strong> this staff?
              </p>

              <TextField
                className="mt-3"
                multiline
                rows={4}
                fullWidth
                size="small"
                name="statusComments"
                label="Your comments"
                variant="outlined"
                placeholder="Your comments"
                value={statusComments}
                onChange={(e) => {
                  setStatusComments(e.target.value || "");
                }}
              />
            </>
          }
          setConfirmationDialog={setShowConfirmReplacement}
          onYes={() => {
            confirmReplacementStaff(
              {
                id: request.id,
                replacingStaffId: replacement.id,
                statusComments,
              },
              () => {
                onClose();
                closePalentDialog();
                setShowConfirmReplacement(false);
              }
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  onWait,
  placementReportDetails,
  changeList,
  prevPlacementReport,
  signatories,
  roles,
  districts,
  sectors,
  degrees,
  specializations,
  schoolPositions,
  qualificationLevels,
  banks,
}) => {
  return {
    user,
    loading,
    onWait,
    placementReportDetails,
    changeList,
    prevPlacementReport,
    signatories,
    roles,
    districts,
    sectors,
    degrees,
    specializations,
    schoolPositions,
    qualificationLevels,
    banks,
  };
};
export default connect(mapStateToProps, {
  addOrUpdateReplacementStaff,
  getReplacementStaffPositions,
  approveReplacementStaff,
  confirmReplacementStaff,
})(PreviewReplacementStaffDialog);
