import {
  IconButton,
  Dialog,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  Box,
  CircularProgress,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
  TextField,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { currencyFormat } from "../../common/components/Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  addOrUpdateTeacher,
  getSchoolPositions,
} from "../../../../store/posts/actions";
import { getSectors } from "../../../../store/posts/actions";
import { getTeacherProfile } from "../../../../store/users/actions";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";

import { isEmpty } from "lodash";
import axios from "axios";
import { CSVLink } from "react-csv";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import moment from "moment";
import { showError } from "../../../toastify";
import defaultPofile from "../../../assets/default-profile.jpg";
// import { idNumberPattern } from "../../common/components/Utils";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import MaskedInput from "react-text-mask";
import joi from "joi";

import { Link } from "react-router-dom";

const personalEmailValidator = joi.object({
  email: joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: ["com", "fr", "rw"] },
  }),
});

const idNumberPattern = [
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
];

export const documents = [
  {
    id: 1,
    name: "Degree",
  },
  {
    id: 2,
    name: "Definitive appointment letter",
  },
  {
    id: 3,
    name: "Peformance evaluation report",
  },
  {
    id: 4,
    name: "Upload your proof of disability (NCPD card for teachers with disability)",
    question: "Do you have disability?",
  },
  {
    id: 5,
    name: "Upload a copy of medical certificate from a referral or District Hospital approved by three (3) Doctors.",
    question: "Do you have chronic disease?",
  },
  {
    id: 6,
    name: "Upload birth certificate and proof of disability/chronic disease of your child",
    question: "Does your child has disability/chronic disease?",
  },
  {
    id: 7,
    name: "Upload marriage certificate and proof of disability/chronic disease of your spouse",
    question: "Does your spouse has disability/chronic disease?",
  },
  {
    id: 8,
    name: "Upload a copy of Official Government mission authorization",
    question: "Is your spouse deployed in the official Government mission?",
  },
];

const TeacherProfile = (props) => {
  useEffect(() => {
    document.title = "TMIS | Security - TeacherProfile";
  }, []);

  const {
    user,
    loading,
    // onWait,
    // teacher,
    // showDialog,
    // setShowDialog,
    // addOrUpdateTeacher,
    // roles,
    // districts,
    // getSectors,
    // sectors,
    // degrees,
    // specializations,
    // schoolPositions,
    // placementReport,
    // getSchoolPositions,

    teacherProfile,
    getTeacherProfile,
  } = props;

  useEffect(() => {
    getTeacherProfile(user.id);
  }, []);

  const [errors, setErrors] = useState({
    attachmentHasErrors: [],
    hasError: false,
  });

  const [formData, setFormData] = useState({
    hasOtherDocument4: "false",
    hasOtherDocument5: "false",
    hasOtherDocument6: "false",
    hasOtherDocument7: "false",
    hasOtherDocument8: "false",
    // hasDisability:"",
    // hasChronicDisease:"",
    // hasChildWithDisabilityOrChronicDisease:"",
    // hasSpouseWithDisabilityOrChronicDisease:"",
    // hasSpouseInOfficialGovernmentMission:"",
    attachments: [],
  });

  // const teacherInfoFormValidator = () => {
  //   const error = {
  //     nidNumberHasError: false,
  //     emailHasError: false,
  //     phoneNumberHasError: false,
  //     employeeIdHasError: false,
  //     staffCodeHasError: false,
  //     employmentTypeHasError: false,
  //     bankAccountNumberHasError: false,
  //     educationCertficateHasError: false,
  //     employmentDateHasError: false,
  //     positionHasError: false,
  //     passportNumberHasError: false,
  //     hasError: false,
  //   };

  //   // console.log(teacherForm.nidNumber.replace(/\s/g, "").length);
  //   if (
  //     ((teacherForm.nidNumber || "").replace(/\s/g, "").length !== 16 &&
  //       (teacherForm.nidNumber || "").replace(/\s/g, "").length !== 10 &&
  //       !teacherForm.passportNumber) ||
  //     !!idHasError
  //   ) {
  //     error.nidNumberHasError = true;
  //     error.hasError = true;
  //   }

  //   if (
  //     !!teacherForm.nidNumber &&
  //     (teacherForm.nidNumber || "").replace(/\s/g, "").length !== 16 &&
  //     !teacherForm.passportNumber
  //   ) {
  //     error.passportNumberHasError = true;
  //     error.hasError = true;
  //   }

  //   if (!teacherForm.employeeId) {
  //     error.employeeIdHasError = true;
  //     error.hasError = true;
  //   }

  //   if (!teacherForm.sdmsStaffCode) {
  //     error.staffCodeHasError = true;
  //     error.hasError = true;
  //   }

  //   if ((teacherForm.phoneNumber || "").replace(/\s/g, "").length !== 10) {
  //     error.phoneNumberHasError = true;
  //     error.hasError = true;
  //   }

  //   if (
  //     !teacherForm.email ||
  //     (!!teacherForm.email &&
  //       personalEmailValidator.validate({ email: teacherForm.email }).error)
  //   ) {
  //     error.emailHasError = true;
  //     error.hasError = true;
  //   }

  //   if (!selectedEmploymentType) {
  //     error.employmentTypeHasError = true;
  //     error.hasError = true;
  //   }

  //   if (!teacherForm.bankId) {
  //     error.bankAccountNumberHasError = true;
  //     error.hasError = true;
  //   }

  //   if (teacherForm.hasEducationCertficate === "") {
  //     error.educationCertficateHasError = true;
  //     error.hasError = true;
  //   }

  //   // if (!selectedDegree) {
  //   //   error.degreeHasError = true;
  //   //   error.hasError = true;
  //   // }

  //   // if (!selectedSpecialization) {
  //   //   error.specializationHasError = true;
  //   //   error.hasError = true;
  //   // }

  //   if (
  //     !position ||
  //     (!!position &&
  //       +position?.budgetPosts <= +position?.numberOfEmployees &&
  //       position.id !== teacher.schoolPositionId)
  //   ) {
  //     error.positionHasError = true;
  //     error.hasError = true;
  //   }

  //   // if (
  //   //   (!!teacherForm.graduationDate &&
  //   //     new Date(teacherForm.graduationDate) > new Date()) ||
  //   //   !teacherForm.graduationDate
  //   // ) {
  //   //   error.graduationDateHasError = true;
  //   //   error.hasError = true;
  //   // }

  //   // if (!teacherForm.employmentDate) {
  //   //   error.employmentDateHasError = true;
  //   //   error.hasError = true;
  //   // }

  //   setErrors(error);

  //   if (error.hasError) {
  //     return true;
  //   }
  //   return false;
  // };

  // const employmentTypes = [
  //   { id: 1, name: "Permanent" },
  //   { id: 2, name: "Contractual" },
  // ];

  // const [isVerifing, setIsVerifing] = useState(false);
  // const [wasVerified, setWasVerified] = useState(teacher.isNIDVerified);
  // const [idHasError, setIdHasError] = useState(false);

  // const [selectedEmploymentType, setSelectedEmploymentType] = useState(
  //   employmentTypes.find(({ id }) => id === teacher.employmentTypeId)
  // );
  // const [selectedDegree, setSelectedDegree] = useState(null);
  // const [selectedSpecialization, setSelectedSpecialization] = useState(null);
  // const [position, setPosition] = useState(null);

  // const [teacherForm, setTeacherForm] = useState({
  //   nidNumber: teacher.nidNumber,
  //   passportNumber: teacher.passportNumber,
  //   nin: teacher.nin,
  //   email: teacher.email,
  //   phoneNumber: teacher.phoneNumber,
  //   gender: teacher.gender,
  //   genderId: teacher.genderId,
  //   countryOfBirth: teacher.countryOfBirth,
  //   countryIdOfBirth: teacher.countryIdOfBirth,
  //   firstName: teacher.firstName,
  //   lastName: teacher.lastName,
  //   civilStatus: teacher.civilStatus,
  //   civilStatusId: teacher.civilStatusId,
  //   spouseNames: teacher.spouseNames,
  //   fatherNames: teacher.fatherNames,
  //   motherNames: teacher.motherNames,
  //   dateOfBirth: teacher.dateOfBirth,
  //   placeOfBirth: teacher.placeOfBirth,
  //   nationalityId: teacher.nationalityId,
  //   villageId: teacher.villageId,
  //   profilePicture: "",
  //   employeeId: teacher.id,
  //   rssbNumber: teacher.rssbNumber,
  //   accountNumber: teacher.accountNumber,
  //   bankId: teacher.bankId,
  //   bankName: teacher.bankName,
  //   sdmsStaffCode: teacher.sdmsStaffCode,
  //   hasEducationCertficate: teacher.hasEducationCertficate,
  //   graduationDate: !!teacher.graduationDate
  //     ? new Date(teacher.graduationDate)
  //     : null,
  //   employmentDate: !!teacher.placementDate
  //     ? new Date(teacher.placementDate)
  //     : null,

  //   appointmentDate: !!teacher.appointmentDate
  //     ? new Date(teacher.appointmentDate)
  //     : null,
  // });

  // const getNidDetails = async (nidNumber) => {
  //   try {
  //     setWasVerified(false);
  //     setIsVerifing(true);

  //     const { data } = await axios.get(
  //       "https://ippis.rw/api/search-tmis-employee/" +
  //         (nidNumber || "").replace(/\s/g, "")
  //     );

  //     setTeacherForm({
  //       ...teacherForm,
  //       nidNumber: nidNumber,
  //       nin: data.nin,
  //       gender: data.gender,
  //       genderId: data.genderId,
  //       countryOfBirth: data.countryOfBirth,
  //       countryIdOfBirth: data.countryIdOfBirth,
  //       firstName: data.firstName,
  //       lastName: data.lastName,
  //       civilStatus: data.civilStatus,
  //       civilStatusId: data.civilStatusId,
  //       spouseNames: data.spouseNames,
  //       fatherNames: data.fatherNames,
  //       motherNames: data.motherNames,
  //       dateOfBirth: data.dateOfBirth,
  //       placeOfBirth: data.placeOfBirth,
  //       nationalityId: data.nationalityId,
  //       villageId: data.villageId,
  //       profilePicture: data.profileImage,

  //       employeeId: data.employee?.id || "",
  //       rssbNumber: data.employee?.rssbNumber || "",
  //       accountNumber: data.employee?.accountNumber || "",
  //       employmentDate: data.employee?.employmentData || null,
  //       bankId: data.employee?.bankId || "",
  //       bankName: data.employee?.bankName || "",
  //     });

  //     setIdHasError(false);
  //     setWasVerified(true);
  //     setIsVerifing(false);
  //   } catch (error) {
  //     setIdHasError(true);
  //     setWasVerified(false);
  //     setIsVerifing(false);
  //   }
  // };

  // useEffect(async () => {
  //   setPosition(
  //     schoolPositions.find(({ id }) => id === teacher.schoolPositionId)
  //   );
  // }, [teacher, schoolPositions]);

  // useEffect(async () => {
  //   setPosition(
  //     schoolPositions.find(({ id }) => id === teacher.schoolPositionId)
  //   );

  //   setSelectedEmploymentType(
  //     employmentTypes.find(({ id }) => id === teacher.employmentTypeId)
  //   );

  //   setSelectedDegree(degrees.find(({ id }) => id === teacher.degreeId));

  //   setSelectedSpecialization(
  //     specializations.find(({ id }) => id === teacher.specializationId)
  //   );

  //   if (!!teacher.nidNumber) {
  //     try {
  //       const { data } = await axios.get(
  //         "https://ippis.rw/api/search-tmis-employee/" +
  //           (teacher.nidNumber || "").replace(/\s/g, "")
  //       );

  //       setTeacherForm({
  //         ...teacherForm,
  //         profilePicture: data.profileImage || "",
  //         employmentDate: data.employee?.employmentData || null,
  //       });
  //     } catch (error) {}
  //   }
  // }, [teacher]);

  // useEffect(() => {
  //   getSchoolPositions("SCHOOL", teacher.schoolId);
  // }, []);

  const formStepper3Validator = () => {
    const error = {
      attachmentHasErrors: [],
      hasError: false,
    };

    documents
      .filter(({ id }) => id <= 3)
      .forEach((doc) => {
        const document = formData.attachments.find(({ id }) => doc.id === id);

        if (!document) {
          error.attachmentHasErrors.push(doc);
          error.hasError = true;
        }
      });

    documents
      .filter(
        ({ id }) => id > 3 && formData[`hasOtherDocument${id}`] === "true"
      )
      .forEach((doc) => {
        const document = formData.attachments.find(({ id }) => doc.id === id);

        if (!document) {
          error.attachmentHasErrors.push(doc);
          error.hasError = true;
        }
      });

    setErrors(error);

    if (error.hasError) {
      showError(`Please upload all the mandatory documents`);

      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formStepper3Validator()) {
      const payload = new FormData();

      const keys = Object.keys(formData);
      for (const key of keys) {
        if (formData.hasOwnProperty(key)) {
          if (key === "attachments" && formData[key] && !!formData[key].length)
            formData[key].forEach((row, i) => {
              payload.append(key, row.file);
              payload.append(`fileTitles`, row.name);
            });
          else payload.append(`${key}`, `${formData[key]}`);
        }
      }

      // saveTrainingRequest(payload, onClose);

      console.log(payload);
    }
  };

  const [showUploadedDocument, setShowUploadedDocument] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState(null);

  const convertToLower = (value) => {
    return value.toLowerCase();
  };
  const isFileTooLarge = (file) => {
    const size = file.size;
    return size > 1074176 ? true : false;
  };

  const handleUploadedPDFFile = (event, document) => {
    let pdfAttachment = event.target.files[0];

    setErrors({ ...errors, pdfAttachmentHasError: false });

    if (convertToLower(pdfAttachment?.name?.substr(-4)) !== ".pdf") {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("File should be pdf format");
    } else if (isFileTooLarge(pdfAttachment)) {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("Allowed file should not exceed 1MB");
    } else {
      const index = formData.attachments.findIndex(
        ({ id }) => id === document.id
      );
      const attachments = [...formData.attachments];
      document.file = pdfAttachment;
      document.filename = pdfAttachment.name;

      const reader = new FileReader();

      reader.onload = (e) => {
        document.url = e.target.result;
      };

      reader.readAsDataURL(pdfAttachment);

      if (index < 0) attachments.push(document);
      else attachments[index] = document;
      setFormData({
        ...formData,
        attachments,
      });
      const index2 = errors.attachmentHasErrors.findIndex(
        ({ id }) => id === document.id
      );
      errors.attachmentHasErrors.splice(index2, 1);
      setErrors(errors);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);

    event.stopPropagation();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
      // style={{
      //   height: "74vh",
      //   backgroundColor: "#f3f4f6",
      //   overflowY: "auto",
      // }}
      >
        <div className="row mx-0 mt-3">
          <div className="col-sm-12">
            <React.Fragment>
              <div>
                <div className="row no-gutters">
                  <div className="col-2 justify-content-center  text-center">
                    <div
                      style={{
                        minHeight: "150px",
                        overflow: "hidden",
                      }}
                    >
                      <>
                        <img
                          className="mt-0 rounded  text-center"
                          src={
                            !!teacherProfile.profilePicture
                              ? "data:image/jpg;base64," +
                                teacherProfile.profilePicture
                              : defaultPofile
                          }
                          style={{
                            border: "1px solid #a7a7a7",
                            height: "150px",
                            width: "120px",
                            overflow: "hidden",
                            borderRadius: "50%",
                          }}
                        />
                      </>
                    </div>
                    <div className="text-primary">
                      <strong>NID PHOTO</strong>{" "}
                    </div>
                    {!!teacherProfile?.id && (
                      <div className="text-primary">
                        <strong>{teacherProfile?.id}</strong>{" "}
                      </div>
                    )}
                    <label className="text-center text-uppercase  mt-n2">
                      <span style={{ fontSize: "12px" }}>
                        {teacherProfile?.lastName} {teacherProfile?.firstName}
                      </span>
                    </label>{" "}
                    {!isEmpty(teacherProfile) && (
                      <FormControl
                        className="mt-3 d-block border rounded pt-2"
                        style={{ backgroundColor: "#eee" }}
                      >
                        <FormLabel className="text-primary  px-2">
                          Education certficate
                        </FormLabel>
                        <RadioGroup
                          className="mt-0 d-flex justify-content-start px-4"
                          row
                          name="hasEducationCertficate"
                          value={
                            teacherProfile?.hasEducationCertficate || false
                          }
                        >
                          {!!JSON.parse(
                            teacherProfile.hasEducationCertficate
                          ) && (
                            <FormControlLabel
                              value={true}
                              control={<Radio disableRipple size="small" />}
                              label="Certfied"
                            />
                          )}
                          {!JSON.parse(
                            teacherProfile.hasEducationCertficate
                          ) && (
                            <FormControlLabel
                              value={false}
                              control={<Radio disableRipple size="small" />}
                              label="Not certfied"
                            />
                          )}
                        </RadioGroup>
                      </FormControl>
                    )}
                    <span className="text-uppercase mt-1 mb-2 d-block">
                      <Link to="/in/security/change-password">
                        Change password
                      </Link>
                    </span>
                  </div>

                  <div
                    className="col-10 row mx-0 "
                    style={{
                      height: "70vh",
                      backgroundColor: "#f3f4f6",
                      overflowY: "auto",
                      // borderRadius: "0.5rem",
                      // boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                      // MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                    }}
                  >
                    <div className="col-4 justify-content-center mt-2">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <>
                              {!teacherProfile.isNIDVerified && !loading && (
                                <span className="loaderIcon text-danger d-flex align-items-center">
                                  <span className="material-icons text-danger">
                                    report_problem
                                  </span>
                                  <span style={{ fontSize: "10px" }}>
                                    Error
                                  </span>
                                </span>
                              )}

                              {!!teacherProfile.isNIDVerified && !loading && (
                                <span className="loaderIcon text-success d-flex align-items-center">
                                  <span className="material-icons text-success ">
                                    verified
                                  </span>
                                  <span style={{ fontSize: "10px" }}>
                                    Verified
                                  </span>
                                </span>
                              )}
                            </>
                          ),
                        }}
                        fullWidth
                        size="small"
                        autoComplete="false"
                        name="nidNumber"
                        label="NID Number"
                        placeholder="NID Number"
                        variant="outlined"
                        value={teacherProfile.nidNumber || ""}
                      />
                    </div>
                    <div className="col-4 text-left mt-2">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        size="small"
                        autoComplete="false"
                        name="passportNumber"
                        label="Passport number"
                        placeholder="Passport number"
                        variant="outlined"
                        value={teacherProfile.passportNumber || ""}
                      />
                    </div>

                    <div className="col-2 text-left mt-2">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        size="small"
                        name="civilStatus"
                        label="Civil Status"
                        variant="outlined"
                        value={teacherProfile.civilStatus || ""}
                      />
                    </div>
                    <div className="col-2 text-left mt-2">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="gender"
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Gender"
                        variant="outlined"
                        className=" font-weight-bold"
                        value={teacherProfile.gender || ""}
                      />
                    </div>
                    <div className="col-4  mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="lastName"
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Last Name"
                        variant="outlined"
                        className=""
                        value={teacherProfile.lastName || ""}
                      />
                    </div>
                    <div className="col-4  mt-3  text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="firstName"
                        InputProps={{
                          readOnly: true,
                        }}
                        label="First Name"
                        variant="outlined"
                        className=""
                        value={teacherProfile.firstName || ""}
                      />
                    </div>

                    <div className="col-4  mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="dateOfBirth"
                        value={
                          !!teacherProfile.dateOfBirth
                            ? moment(teacherProfile.dateOfBirth).format(
                                "DD/MM/yyyy"
                              )
                            : ""
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Date Of Birth"
                        variant="outlined"
                      />
                    </div>
                    <div className="col-4  mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="countryOfBirth"
                        value={teacherProfile.countryOfBirth || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Country of Birth"
                        variant="outlined"
                        className=""
                      />
                    </div>
                    <div className="col-4  mt-3 text-left">
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="placeOfBirth"
                        value={teacherProfile.placeOfBirth || ""}
                        label="Place of Birth"
                        variant="outlined"
                        className=""
                      />
                    </div>

                    <div className="col-4  mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="nationality"
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Nationality"
                        placeholder="Nationality"
                        variant="outlined"
                        className=" font-weight-bold"
                        value={teacherProfile.nationalityId || ""}
                      />
                    </div>

                    <div className="col-4 mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="spouseNames"
                        value={teacherProfile?.spouseNames || "N/A"}
                        label="Your spouse names"
                        placeholder="Your spouse names"
                        variant="outlined"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </div>
                    <div className="col-2 mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="employeeId"
                        value={teacherProfile?.id || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Employee ID"
                        placeholder="Employee ID"
                        variant="outlined"
                      />
                    </div>
                    <div className="col-2 mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="rssbNumber"
                        value={teacherProfile?.rssbNumber || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                        label="RSSB Number"
                        placeholder="RSSB Number"
                        variant="outlined"
                        className=""
                      />
                    </div>
                    <div className="col-4 mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="bankAccount"
                        value={
                          !teacherProfile.accountNumber
                            ? ""
                            : teacherProfile.accountNumber +
                              " (" +
                              teacherProfile.bankName +
                              ")"
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Bank Account"
                        placeholder="Bank Account"
                        variant="outlined"
                      />
                    </div>
                    <div className="col-6 mt-3 ">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="rssbNumber"
                        value={teacherProfile?.positionName || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Position"
                        placeholder="Position"
                        variant="outlined"
                        className=""
                      />
                    </div>
                    <div className="col-6 mt-3 ">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="schoolName"
                        value={
                          !!teacherProfile?.schoolName
                            ? teacherProfile?.schoolName +
                              "/" +
                              teacherProfile?.sectorName +
                              "/" +
                              teacherProfile?.districtName
                            : ""
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        label="School"
                        placeholder="School"
                        variant="outlined"
                        className=""
                      />
                    </div>

                    <div className="col-3 mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="teachingLevel"
                        value={teacherProfile?.teachingLevelName || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Teaching Level"
                        placeholder="Teaching Level"
                        variant="outlined"
                      />
                    </div>

                    <div className="col-3 mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="qualificationLevel"
                        value={teacherProfile?.qualificationLevelName || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Position qualification"
                        placeholder="Position qualification"
                        variant="outlined"
                      />
                    </div>

                    <div className="col-6 mt-3 ">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="appointmentDate"
                        value={
                          !!teacherProfile.appointmentDate
                            ? moment(teacherProfile.appointmentDate).format(
                                "DD/MM/yyyy"
                              )
                            : ""
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Position appointment date"
                        variant="outlined"
                      />
                    </div>

                    <div className="col-4  mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        size="small"
                        autoComplete="false"
                        name="phone"
                        label="Phone number"
                        placeholder="Phone number"
                        variant="outlined"
                        value={teacherProfile.phoneNumber || ""}
                      />
                    </div>

                    <div className="col-4  mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        size="small"
                        autoComplete="false"
                        name="email"
                        label="Personal email"
                        placeholder="Personal email"
                        variant="outlined"
                        value={teacherProfile.email || ""}
                      />
                    </div>

                    <div className="mt-3 col-4">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        size="small"
                        autoComplete="false"
                        name="employmentTypeName"
                        label="Employment Type"
                        placeholder="Employment Type"
                        variant="outlined"
                        value={teacherProfile.employmentTypeName || ""}
                      />
                    </div>

                    <div className="mt-3 col-4 mb-3">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        size="small"
                        autoComplete="false"
                        name="degreeName"
                        label="Your degree"
                        placeholder="Your degree"
                        variant="outlined"
                        value={teacherProfile.degreeName || ""}
                      />
                    </div>
                    <div className="mt-3 col-4 mb-3">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        size="small"
                        autoComplete="false"
                        name="specialization"
                        label="Specialization"
                        placeholder="Specialization"
                        variant="outlined"
                        value={teacherProfile.specialization || ""}
                      />
                    </div>
                    <div className="mt-3 col-4 mb-3">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="graduationDate"
                        value={
                          !!teacherProfile.graduationDate
                            ? moment(teacherProfile.graduationDate).format(
                                "DD/MM/yyyy"
                              )
                            : ""
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Graduation date"
                        variant="outlined"
                      />
                    </div>
                    {/* <div className="col-12 row no-gutters px-3">
                      <fieldset
                        className={`col-12 row no-gutters px-2 form-group border border-${
                          !!errors.attachmentHasErrors.length &&
                          !!errors.attachmentHasErrors.find(({ id }) => id <= 3)
                            ? "danger"
                            : "primary"
                        }`}
                      >
                        <legend
                          className={`col-12 w-auto px-2 text-${
                            !!errors.attachmentHasErrors.length &&
                            !!errors.attachmentHasErrors.find(
                              ({ id }) => id <= 3
                            )
                              ? "danger"
                              : "primary"
                          }`}
                          style={{
                            fontFamily:
                              '"Roboto","Helvetica","Arial",sans-serif',
                            fontWeight: 400,
                            fontSize: "0.82rem",
                            lineHeight: "1em",
                            letterSpacing: "0.00938em",
                          }}
                        >
                          Mandatory documents
                        </legend>

                        {documents
                          .filter(({ id }) => id <= 3)
                          .map((document) => (
                            <div className=" col-4" key={document.id}>
                              <fieldset
                                className={`form-group ${
                                  !!(
                                    !!errors.attachmentHasErrors.length &&
                                    errors.attachmentHasErrors.find(
                                      (doc) => document === doc
                                    )
                                  )
                                    ? "border border-danger"
                                    : "border"
                                } px-2`}
                              >
                                <legend
                                  className="w-auto px-2"
                                  style={{
                                    fontFamily:
                                      '"Roboto","Helvetica","Arial",sans-serif',
                                    fontWeight: 400,
                                    fontSize: "0.82rem",
                                    lineHeight: "1em",
                                    letterSpacing: "0.00938em",
                                    color: `${
                                      !!(
                                        !!errors.attachmentHasErrors.length &&
                                        errors.attachmentHasErrors.find(
                                          (doc) => document === doc
                                        )
                                      )
                                        ? "red"
                                        : "rgba(0, 0, 0, 0.6)"
                                    }`,
                                  }}
                                >
                                  {document.id}.{" "}
                                  <span className="ml-1">{document.name}</span>
                                </legend>
                                <div className="form-group1 mb-2">
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        <i className="fas fa-paperclip"></i>
                                      </span>
                                    </div>
                                    {formData.attachments.find(
                                      (doc) =>
                                        document === doc && !!doc.filename
                                    ) ? (
                                      <div
                                        className="form-control"
                                        style={{
                                          backgroundColor: "rgb(229, 246, 253)",

                                          color: "#007bff",
                                        }}
                                      >
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setShowUploadedDocument(true);
                                            setUploadedDocument({
                                              name: document.name,
                                              url: formData.attachments.find(
                                                (doc) =>
                                                  document === doc &&
                                                  !!doc.filename
                                              ).url,
                                            });
                                          }}
                                        >
                                          {
                                            formData.attachments.find(
                                              (doc) =>
                                                document === doc &&
                                                !!doc.filename
                                            ).filename
                                          }
                                        </span>

                                        <IconButton
                                          size="small"
                                          onClick={handleOpenMenu}
                                          style={{
                                            marginTop: "-5px",
                                            marginRight: "-10px",
                                          }}
                                          color="info"
                                          className="float-right"
                                          aria-label="view"
                                        >
                                          <MoreHorizIcon />
                                        </IconButton>

                                        <Menu
                                          anchorEl={anchorEl}
                                          id="account-menu"
                                          open={open}
                                          onClose={(e) => {
                                            handleCloseMenu();
                                            e.stopPropagation();
                                          }}
                                          PaperProps={{
                                            elevation: 0,
                                            sx: {
                                              overflow: "visible",
                                              filter:
                                                "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                              mt: 1.5,
                                              "& .MuiAvatar-root": {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 2,
                                              },
                                              "&:before": {
                                                content: '""',
                                                display: "block",
                                                position: "absolute",
                                                top: 0,
                                                right: 15,
                                                width: 10,
                                                height: 10,
                                                bgcolor: "background.paper",
                                                transform:
                                                  "translateY(-50%) rotate(45deg)",
                                                zIndex: 0,
                                              },
                                            },
                                          }}
                                          transformOrigin={{
                                            horizontal: "right",
                                            vertical: "top",
                                          }}
                                          anchorOrigin={{
                                            horizontal: "right",
                                            vertical: "bottom",
                                          }}
                                        >
                                          <span>
                                            <MenuItem
                                              onClick={(e) => {
                                                handleCloseMenu();
                                                setShowUploadedDocument(true);
                                                setUploadedDocument({
                                                  name: document.name,
                                                  url: formData.attachments.find(
                                                    (doc) =>
                                                      document === doc &&
                                                      !!doc.filename
                                                  ).url,
                                                });
                                              }}
                                              className="text-primary font-weight-bolder"
                                            >
                                              <span className="material-icons  mr-1">
                                                open_in_new
                                              </span>
                                              Preview
                                            </MenuItem>
                                          </span>

                                          <span>
                                            <MenuItem
                                              onClick={(e) => {
                                                handleCloseMenu();
                                                const tmpDocuments = [
                                                  ...formData.attachments,
                                                ];
                                                const index =
                                                  tmpDocuments.findIndex(
                                                    ({ id }) =>
                                                      id === document.id
                                                  );

                                                tmpDocuments.splice(index, 1);
                                                setFormData({
                                                  ...formData,
                                                  attachments: tmpDocuments,
                                                });
                                              }}
                                              className="text-danger"
                                            >
                                              <span className="material-icons mr-1">
                                                remove_circle_outline
                                              </span>
                                              Remove
                                            </MenuItem>
                                          </span>
                                        </Menu>
                                      </div>
                                    ) : (
                                      <>
                                        <input
                                          type="file"
                                          name="file"
                                          className="form-control"
                                          accept="application/pdf"
                                          placeholder="Select pdf file"
                                          onChange={(e) =>
                                            handleUploadedPDFFile(e, document)
                                          }
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                          ))}
                      </fieldset>
                    </div> */}

                    {/* <div className="col-12 row no-gutters px-3">
                      <fieldset
                        className={`col-12 row no-gutters px-2 form-group border border-${
                          !!errors.attachmentHasErrors.length &&
                          !!errors.attachmentHasErrors.find(({ id }) => id > 3)
                            ? "danger"
                            : "primary"
                        }`}
                      >
                        <legend
                          className={`col-12 w-auto px-2 text-${
                            !!errors.attachmentHasErrors.length &&
                            !!errors.attachmentHasErrors.find(
                              ({ id }) => id > 3
                            )
                              ? "danger"
                              : "primary"
                          }`}
                          style={{
                            fontFamily:
                              '"Roboto","Helvetica","Arial",sans-serif',
                            fontWeight: 400,
                            fontSize: "0.82rem",
                            lineHeight: "1em",
                            letterSpacing: "0.00938em",
                          }}
                        >
                          Other documents
                        </legend>

                        {documents
                          .filter(({ id }) => id > 3)
                          .map((document) => (
                            <React.Fragment key={document.id}>
                              <div className="col-12">
                                <FormControl
                                  className="mt-3 d-block border rounded pt-2"
                                  // style={{ backgroundColor: "#eee" }}
                                >
                                  <FormLabel className="text-primary  px-2">
                                    {document.question}
                                  </FormLabel>
                                  <RadioGroup
                                    className="mt-0 d-flex justify-content-start px-4"
                                    row
                                    name="hasEducationCertficate"
                                    value={
                                      formData[
                                        `hasOtherDocument${document.id}`
                                      ] || ""
                                    }
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        [`hasOtherDocument${document.id}`]:
                                          e.target.value,
                                      });
                                    }}
                                  >
                                    <FormControlLabel
                                      value={false}
                                      control={
                                        <Radio disableRipple size="small" />
                                      }
                                      label="No"
                                    />
                                    <FormControlLabel
                                      value={true}
                                      control={
                                        <Radio disableRipple size="small" />
                                      }
                                      label="Yes"
                                    />
                                  </RadioGroup>
                                  {!!formData[
                                    `hasOtherDocument${document.id}`
                                  ] &&
                                    formData[
                                      `hasOtherDocument${document.id}`
                                    ] !== "false" && (
                                      <fieldset
                                        className={`form-group ${
                                          !!(
                                            !!errors.attachmentHasErrors
                                              .length &&
                                            errors.attachmentHasErrors.find(
                                              (doc) => document === doc
                                            )
                                          )
                                            ? "border border-danger"
                                            : "border"
                                        } px-2`}
                                      >
                                        <legend
                                          className="w-auto px-2"
                                          style={{
                                            fontFamily:
                                              '"Roboto","Helvetica","Arial",sans-serif',
                                            fontWeight: 400,
                                            fontSize: "0.82rem",
                                            lineHeight: "1em",
                                            letterSpacing: "0.00938em",
                                            color: `${
                                              !!(
                                                !!errors.attachmentHasErrors
                                                  .length &&
                                                errors.attachmentHasErrors.find(
                                                  (doc) => document === doc
                                                )
                                              )
                                                ? "red"
                                                : "rgba(0, 0, 0, 0.6)"
                                            }`,
                                          }}
                                        >
                                          <span className="ml-1">
                                            {document.name}
                                          </span>
                                        </legend>
                                        <div className="form-group1 mb-2">
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                <i className="fas fa-paperclip"></i>
                                              </span>
                                            </div>
                                            {formData.attachments.find(
                                              (doc) =>
                                                document === doc &&
                                                !!doc.filename
                                            ) ? (
                                              <div
                                                className="form-control"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(229, 246, 253)",

                                                  color: "#007bff",
                                                }}
                                              >
                                                <span
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    setShowUploadedDocument(
                                                      true
                                                    );
                                                    setUploadedDocument({
                                                      name: document.name,
                                                      url: formData.attachments.find(
                                                        (doc) =>
                                                          document === doc &&
                                                          !!doc.filename
                                                      ).url,
                                                    });
                                                  }}
                                                >
                                                  {
                                                    formData.attachments.find(
                                                      (doc) =>
                                                        document === doc &&
                                                        !!doc.filename
                                                    ).filename
                                                  }
                                                </span>

                                                <IconButton
                                                  size="small"
                                                  onClick={handleOpenMenu}
                                                  style={{
                                                    marginTop: "-5px",
                                                    marginRight: "-10px",
                                                  }}
                                                  color="info"
                                                  className="float-right"
                                                  aria-label="view"
                                                >
                                                  <MoreHorizIcon />
                                                </IconButton>

                                                <Menu
                                                  anchorEl={anchorEl}
                                                  id="account-menu"
                                                  open={open}
                                                  onClose={(e) => {
                                                    handleCloseMenu();
                                                    e.stopPropagation();
                                                  }}
                                                  PaperProps={{
                                                    elevation: 0,
                                                    sx: {
                                                      overflow: "visible",
                                                      filter:
                                                        "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                      mt: 1.5,
                                                      "& .MuiAvatar-root": {
                                                        width: 32,
                                                        height: 32,
                                                        ml: -0.5,
                                                        mr: 2,
                                                      },
                                                      "&:before": {
                                                        content: '""',
                                                        display: "block",
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 15,
                                                        width: 10,
                                                        height: 10,
                                                        bgcolor:
                                                          "background.paper",
                                                        transform:
                                                          "translateY(-50%) rotate(45deg)",
                                                        zIndex: 0,
                                                      },
                                                    },
                                                  }}
                                                  transformOrigin={{
                                                    horizontal: "right",
                                                    vertical: "top",
                                                  }}
                                                  anchorOrigin={{
                                                    horizontal: "right",
                                                    vertical: "bottom",
                                                  }}
                                                >
                                                  <span>
                                                    <MenuItem
                                                      onClick={(e) => {
                                                        handleCloseMenu();
                                                        setShowUploadedDocument(
                                                          true
                                                        );
                                                        setUploadedDocument({
                                                          name: document.name,
                                                          url: formData.attachments.find(
                                                            (doc) =>
                                                              document ===
                                                                doc &&
                                                              !!doc.filename
                                                          ).url,
                                                        });
                                                      }}
                                                      className="text-primary font-weight-bolder"
                                                    >
                                                      <span className="material-icons  mr-1">
                                                        open_in_new
                                                      </span>
                                                      Preview
                                                    </MenuItem>
                                                  </span>

                                                  <span>
                                                    <MenuItem
                                                      onClick={(e) => {
                                                        handleCloseMenu();
                                                        const tmpDocuments = [
                                                          ...formData.attachments,
                                                        ];
                                                        const index =
                                                          tmpDocuments.findIndex(
                                                            ({ id }) =>
                                                              id === document.id
                                                          );

                                                        tmpDocuments.splice(
                                                          index,
                                                          1
                                                        );
                                                        setFormData({
                                                          ...formData,
                                                          attachments:
                                                            tmpDocuments,
                                                        });
                                                      }}
                                                      className="text-danger"
                                                    >
                                                      <span className="material-icons mr-1">
                                                        remove_circle_outline
                                                      </span>
                                                      Remove
                                                    </MenuItem>
                                                  </span>
                                                </Menu>
                                              </div>
                                            ) : (
                                              <>
                                                <input
                                                  type="file"
                                                  name="file"
                                                  className="form-control"
                                                  accept="application/pdf"
                                                  placeholder="Select pdf file"
                                                  onChange={(e) =>
                                                    handleUploadedPDFFile(
                                                      e,
                                                      document
                                                    )
                                                  }
                                                />
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </fieldset>
                                    )}
                                </FormControl>
                              </div>
                           
                            </React.Fragment>
                          ))}
                      </fieldset>
                    </div> */}

                    {/* <div className="col-lg-12 mt-1 pb-2 text-center">
                      <Button
                        disabled={loading}
                        color="primary"
                        variant="contained"
                        disableElevation
                        size="sm"
                        className="px-5  "
                        onClick={onSave}
                      >
                        {loading ? "Wait..." : "Save Changes"}
                      </Button>
                    </div> */}
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
        </div>
      </div>

      {showUploadedDocument && uploadedDocument && (
        <PreviewPdfUrl
          isBase64={true}
          showDocument={showUploadedDocument}
          setShowDocument={setShowUploadedDocument}
          document={{
            name: `${uploadedDocument?.name || ""}`,
            teacherName: `${teacherProfile?.lastName || ""} ${
              teacherProfile?.firstName || ""
            }`,
            url: uploadedDocument?.url || "",
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  onWait,
  placementReportDetails,
  changeList,
  prevPlacementReport,
  signatories,
  roles,
  districts,
  sectors,
  degrees,
  specializations,
  schoolPositions,
  teacherProfile,
}) => {
  return {
    user,
    loading,
    onWait,
    placementReportDetails,
    changeList,
    prevPlacementReport,
    signatories,
    roles,
    districts,
    sectors,
    degrees,
    specializations,
    schoolPositions,
    teacherProfile,
  };
};
export default connect(mapStateToProps, {
  addOrUpdateTeacher,
  getSectors,
  getSchoolPositions,
  getTeacherProfile,
})(TeacherProfile);
